import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FaUser, FaList, FaEyeSlash, FaLaptop } from "react-icons/fa";
import { AppContext } from "../../context/appContext";

import LearnMoreModal from "../modals/learnMoreModal";

import logo1 from "../../assets/img/transhives-logo.png";
import logo2 from "../../assets/img/favicon.png";
import bgImage from "../../assets/img/curved-images/curved11.jpg";
import { SmartP } from "../common/text";
import Button from "../common/button";

function Sidebar() {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const {
    isHideMenu,
    setIsHideMenu,
    recentlyViewed,
    currentPageID,
    setCurrentPageID,
    getSession,
  } = useContext(AppContext);
  const [sideBarHeight, setSideBarHeight] = useState("calc(100vh - 360px)");
  const session = getSession();
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  useEffect(() => {
    if (isMobile) setSideBarHeight("calc(100vh - 375px)");
  }, []);

  return <>{renderContent()}</>;

  function renderContent() {
    if (!isHideMenu) {
      return (
        <>
          <aside
            className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white"
            id="sidenav-main"
          >
            <center>
              <a className="navbar-brand m-0" target="_blank">
                <img src={logo1} className="navbar-brand-img" height="100%" />

                {isMobile && (
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        paddingLeft: "0px",
                      }}
                      onClick={() => setIsHideMenu(true)}
                    >
                      <FaEyeSlash /> Hide Menu
                    </button>
                  </p>
                )}
              </a>
            </center>

            <hr className="horizontal dark mt-0" />
            <div
              className="collapse navbar-collapse  w-auto "
              id="sidenav-collapse-main"
              style={{ height: sideBarHeight }}
            >
              {currentPageID === "careerPage" && session.authLevel === 4 ? (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to={"/dashboard"}>
                      <a className="nav-link">
                        <span className="nav-link-text ms-1">
                          This is the candidate view.
                          <br />
                          Click to go to Dashboard.
                          <br />
                        </span>
                      </a>
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/profile">
                      <div
                        style={{
                          backgroundColor:
                            currentPageID === "profile" ? "#f5f5fc" : "",
                        }}
                      >
                        <a className="nav-link">
                          <div className="icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FaUser />
                          </div>
                          <span className="nav-link-text ms-1">My Profile</span>
                        </a>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/applications">
                      <div
                        style={{
                          backgroundColor:
                            currentPageID === "applications" ? "#f5f5fc" : "",
                        }}
                      >
                        <a className="nav-link">
                          <div className="icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FaList />
                          </div>
                          <span className="nav-link-text ms-1">
                            My Applications
                          </span>
                        </a>
                      </div>
                    </Link>
                  </li>
                  {recentlyViewed.linkID && (
                    <li className="nav-item">
                      <br />
                      <Link to={"/pages/" + recentlyViewed.linkID}>
                        <a className="nav-link">
                          <span className="nav-link-text ms-1">
                            Recently Viewed:
                            <br />
                            <SmartP
                              _id={recentlyViewed.orgName}
                              text={recentlyViewed.orgName}
                              maxLength={20}
                            />
                          </span>
                        </a>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </div>
            <div className="sidenav-footer mx-3">
              <div
                className="card card-background shadow-none card-background-mask-secondary"
                id="sidenavCard"
              >
                <div
                  className="full-background"
                  style={{ backgroundImage: `url(${bgImage})` }}
                ></div>
                <div className="card-body text-start p-3 w-100">
                  <img src={logo2} height="50px" />
                  <div className="docs-info">
                    <h6 className="text-white up mb-0">Powered By</h6>
                    <p className="text-xs font-weight-bold">
                      <b>TransHives</b>: Helping candidates find organisations
                      they love and helping organisations hire talent they
                      admire.
                    </p>
                    <Link to="/help" target="_blank">
                      <Button
                        color="white"
                        type={"aArrow"}
                        colSize={12}
                        text="Help Centre"
                        onClick={() => console.log("")}
                        // onClick={() => setShowLearnMoreModal(true)}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </aside>
          {showLearnMoreModal && (
            <LearnMoreModal
              type="sidebar"
              showLearnMoreModal={showLearnMoreModal}
              setShowLearnMoreModal={() => setShowLearnMoreModal(false)}
            />
          )}
        </>
      );
    }
  }
}

export default Sidebar;
