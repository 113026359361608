import React from "react";
import { FaSearch } from "react-icons/fa";

import Input from "../../common/input";
import Button from "../../common/button";

function TeamFilters(props) {
  return (
    <div className="col-lg-12 mt-lg-0 mt-4">
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-7">
            <div className="row">
              <div className="col-9">
                <div className="row">
                  <Input
                    disabled={false}
                    colSize={12}
                    mdSize={12}
                    type="text"
                    name="teamName"
                    placeholder="Team Name (E.g.: Awesome Team)"
                    value={props.teamName}
                    onChange={(value) => props.setTeamName(value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="row">
                  <Button
                    type={"withBgWithIcon"}
                    colSize={6}
                    icon={FaSearch}
                    onClick={() => props.applyFilters()}
                  />
                  <Button
                    type={"noBgNoIcon"}
                    colSize={6}
                    text="Reset"
                    onClick={() => props.resetFilters()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamFilters;
