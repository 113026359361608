import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle } from "react-icons/fa";

import Button from "../../common/button";
import TeamInputs from "./teamInputs";

function TeamInputsModal(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal show={true} backdrop="static" size="lg" centered>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal.Body>
          <div className="row">
            <div className="col-10"></div>
            <Button
              type={"noBgWithIcon"}
              colSize={2}
              icon={FaTimesCircle}
              text="Close"
              onClick={() => props.closeModal()}
            />
          </div>

          <div className="col-12">
            <TeamInputs
              type={props.type}
              team={props.team}
              setIsLoading={(value) => setIsLoading(value)}
              teamCreated={(value) => props.teamCreated(value)}
              teamUpdated={(value) => props.teamUpdated(value)}
              setAlertType={(value) => props.setAlertType(value)}
              setAlertText={(value) => props.setAlertText(value)}
              closeModal={() => props.closeModal()}
            />
          </div>
        </Modal.Body>
      </LoadingOverlay>
    </Modal>
  );
}

export default TeamInputsModal;
