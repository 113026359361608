import React from "react";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle } from "react-icons/fa";

import Button from "../../common/button";
import { Link } from "react-router-dom";

function JobInfoModal(props) {
  const navigate = useNavigate();
  return (
    <Modal show={true} backdrop="static" centered>
      <Modal.Body>
        <div className="row">
          <div className="col-2"></div>
          {props.job && props.job.status !== "completed" && (
            <Button
              type={"noBgNoIcon"}
              colSize={4}
              icon={FaTimesCircle}
              text={"Mark As Complete"}
              onClick={() => props.markAsComplete()}
            />
          )}
          {props.job && props.job.status === "completed" && (
            <div className="col-4"></div>
          )}
          <Button
            type={"withBgNoIcon"}
            colSize={4}
            icon={FaTimesCircle}
            text={"View Candidates"}
            onClick={() => navigate("/candidates?jobID=" + props.job._id)}
          />
          <Button
            type={"noBgWithIcon"}
            colSize={2}
            icon={FaTimesCircle}
            text="Close"
            onClick={() => props.closeModal()}
          />
        </div>

        <div className="row">
          <div className="col-12">
            <h6 className="mb-0">{props.job.jobName}</h6>
            <p className="mb-0"> {props.job.location}</p>
            <br />
            <p className="mb-0">
              Shareable Universal Link:
              <br />
              <Link
                to={"/pages/" + props.linkID + "?jobID=" + props.job._id}
                target={"_blank"}
              >
                <font style={{ color: "blue" }}>
                  app.transhives.com/pages/{props.linkID}?jobID={props.job._id}
                </font>
              </Link>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <br />
            <center>
              <span className="badge bg-gradient-primary ms-auto">
                Min. Education: {props.job.minEdu.label}
              </span>{" "}
              <span className="badge bg-gradient-primary ms-auto">
                Min. Experience: {props.job.minExp.label}
              </span>
            </center>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-12">
            <p className="text-sm">{props.job.desc}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default JobInfoModal;
