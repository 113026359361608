import React from "react";
import { Link } from "react-router-dom";
import Button from "../../common/button";

function LinkSuccess(props) {
  return (
    <div className="row">
      <p className="mb-0">
        Congrats! You have linked your
        <br />
        account. You can review your applications from the 'Applications' tab.
        <br />
        <br />
      </p>
      <Link to="/profile">
        <Button
          type={"withBgNoIcon"}
          colSize={12}
          text="Go to Profile & Applications"
          onClick={() => console.log("")}
        />
      </Link>
    </div>
  );
}

export default LinkSuccess;
