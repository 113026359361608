export function getSmartString(text, start, end) {
  if (text.length > end) {
    return text.substring(start, end) + "...";
  }
  return text;
}

export function isSubstring(text, maxLength) {
  return text.length > maxLength;
}

export function getDate(rawDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(rawDate);
  return `${date.getDate()}/${months[date.getMonth()]}/${date.getFullYear()}`;
}

export function getDateTime(rawDateTime) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(rawDateTime);
  const formattedDate = `${date.getDate()}/${
    months[date.getMonth()]
  }/${date.getFullYear()}`;
  const formattedTime = `${date.getHours()}:${
    (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()
  }`;
  return `${formattedDate} ${formattedTime}`;
}

export function isWithinMonth(rawDate, month) {
  const dateToCheck = new Date(rawDate);
  dateToCheck.setMonth(dateToCheck.getMonth() + month);

  return new Date() < dateToCheck;
}
