import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../../utils/axiosConfig";
import LoadingOverlay from "react-loading-overlay";

import { AppContext } from "../../../context/appContext";
import Auth from "../../auth/auth";

import Sidebar from "../../layout/sidebar";
import Footer from "../../layout/footer";
import Alert from "../../common/alert";
import Header from "../../layout/header";
import ApplicationCard from "./applicationCard";
import PageTitle from "../../layout/pageTitle";

function Applications() {
  const {
    getSession,
    isShowAuth,
    setIsShowAuth,
    setCurrentPageID,
    handleSignOut,
  } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [isShowModal, setIsShowModal] = useState(false);
  const [modalToShow, setModalToShow] = useState("viewJob");

  const [applications, setApplications] = useState([]);

  useEffect(() => {
    setCurrentPageID("applications");
    document.title = "My Applications | TransHives";
    if (session.authLevel >= 3) fetchData(session);
    else setIsShowAuth(true);
  }, []);

  async function fetchData(session) {
    setIsLoading(true);

    try {
      const response = await axiosInstance.get("/candidate/user", {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      setApplications(response.data);
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <Sidebar />

      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        {alertType && (
          <Alert
            alertType={alertType}
            alertText={alertText}
            setAlertType={() => setAlertType(null)}
            setAlertText={() => setAlertText(null)}
          />
        )}

        <Header />
        {session.authLevel >= 3 && (
          <>
            <LoadingOverlay active={isLoading} spinner text="Loading...">
              <div className="container-fluid py-4">
                <PageTitle title={"Applications"} />
                <div className="row">
                  {applications.map((application) => (
                    <ApplicationCard
                      key={application._id}
                      application={application}
                    />
                  ))}
                </div>
              </div>
            </LoadingOverlay>
            <Footer />
          </>
        )}
      </main>

      {isShowAuth && (
        <Auth isFromApply={false} onAuth={(session) => fetchData(session)} />
      )}
    </>
  );
}

export default Applications;
