import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import { FaCheckCircle, FaChevronCircleRight } from "react-icons/fa";

import Button from "../common/button";

function ShowSuccessModal(props) {
  const navigate = useNavigate();

  return (
    <Modal show={true} backdrop="static" size="sm" centered>
      <Modal.Body>
        <div className="row">
          <div className="col-12">{renderContent()}</div>
        </div>
      </Modal.Body>
    </Modal>
  );

  function renderContent() {
    if (props.type === "withClose") {
      return (
        <center>
          <br />
          <br />
          <div className="icon icon-shape icon-xl rounded-circle bg-gradient-success shadow text-center py-3 mx-auto">
            <FaCheckCircle size="100%" style={{ color: "white" }} />
          </div>
          <br />
          <br />
          <h5>Successfully {props.header}!</h5>
          <p className="mb-0">{props.text}</p>
          <br />
          <Button
            type={"withBgWithIconRight"}
            colSize={8}
            icon={FaChevronCircleRight}
            text="Close & GO"
            onClick={() => props.closeModal()}
          />
        </center>
      );
    } else if (props.type === "withRedirect") {
      return (
        <center>
          <br />
          <br />
          <div className="icon icon-shape icon-xl rounded-circle bg-gradient-success shadow text-center py-3 mx-auto">
            <FaCheckCircle size="100%" style={{ color: "white" }} />
          </div>
          <br />
          <br />
          <h5>Successfully {props.header}!</h5>
          <p className="mb-0">{props.text}</p>
          <br />
          <Button
            type={"withBgWithIconRight"}
            colSize={8}
            icon={FaChevronCircleRight}
            text="Close & GO"
            onClick={() =>
              navigate(props.redirectTo, { state: { navVars: props.navVars } })
            }
          />
        </center>
      );
    }
  }
}

export default ShowSuccessModal;
