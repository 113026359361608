import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import { AppContext } from "../../../context/appContext";
import { FaCheck, FaChevronCircleRight } from "react-icons/fa";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../../utils/axiosConfig";

import Alert from "../../common/alert";
import Input from "../../common/input";
import Button from "../../common/button";
import T360Schedule from "../../modals/t360Schedule";
import ShowSuccessModal from "../../modals/showSuccessModal";

import logo from "../../../assets/img/transhives-logo.png";
import bgImage from "../../../assets/img/curved-images/curved7.jpg";
import CountDownTimer from "../../common/cdTimer";
import { Link } from "react-router-dom";

function DashboardCreate() {
  const cdTimer = CountDownTimer(120);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const navigate = useNavigate();
  const location = useLocation();

  const {
    getSession,
    handleSignedIn,
    handleSignOut,
    setIsShowAuth,
    setCurrentPageID,
  } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [stepToShow, setstepToShow] = useState("step1");

  const [isShowModal, setIsShowModal] = useState(false);
  const [modalToShow, setModalToShow] = useState("");

  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailOTP, setEmailOTP] = useState("");
  const [isResendEmail, setIsResendEmail] = useState(false);

  useEffect(() => {
    setCurrentPageID("dashboardCreate");
    document.title = "Explore TransHives | TransHives";
    if (session.isActive) {
      if (location.state && location.state.stepToShow) {
        setstepToShow(location.state.stepToShow);
        setEmail(session.email);
        setIsLoading(false);
      } else navigate("/dashboard");
    } else setIsLoading(false);
  }, []);

  function saveOrg() {
    if (session.isActive && session.authLevel >= 3) saveOrgExisting();
    else saveOrgNew();
  }

  async function saveOrgExisting() {
    setIsLoading(true);

    const body = {
      orgName: orgName,
    };

    try {
      const response = await axiosInstance.post("/org/create", body, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      const authHeader = response.headers.authorization;
      const token = authHeader.split(" ")[1];

      const sessionUpdate = {
        isActive: true,
        authLevel: response.data.authLevel,
        email: response.data.email,
        token: token,
        isVerified: response.data.isVerified,
      };
      handleSignedIn(sessionUpdate);

      setPassword("");
      setConfirmPassword("");

      setstepToShow("step2");
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  async function saveOrgNew() {
    const regexPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (password === "" || !regexPattern.test(password)) {
      setAlertType("info");
      setAlertText(
        "A password needs at least eight characters, one uppercase letter, one lowercase letter, one number and one special character."
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      if (password === confirmPassword) {
        setIsLoading(true);
        const body = {
          orgName: orgName,
          email: email.trim(),
          password: password,
          confirmPassword: confirmPassword,
        };

        try {
          const response = await axiosInstance.post(`/auth/org/create`, body);

          const authHeader = response.headers.authorization;
          const token = authHeader.split(" ")[1];

          const session = {
            isActive: true,
            authLevel: response.data.authLevel,
            email: response.data.email,
            token: token,
            isVerified: response.data.isVerified,
          };
          handleSignedIn(session);

          setPassword("");
          setConfirmPassword("");

          setstepToShow("step2");
          setAlertType("");
          setAlertText("");
        } catch (error) {
          if (error.response && error.response.status === 600) handleSignOut();
          else if (error.response && error.response.status === 422) {
            setAlertType("error");
            setAlertText("Invalid email address!");
            window.scrollTo({ top: 0, behavior: "smooth" });
          } else if (error.response && error.response.status === 429) {
            setAlertType("error");
            setAlertText("Too many requests from your network!");
            window.scrollTo({ top: 0, behavior: "smooth" });
          } else {
            setAlertType("error");
            setAlertText(error.message);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }
        setIsLoading(false);
      } else {
        setAlertType("error");
        setAlertText("Passwords don't match. Please try again!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }

  async function makePayment() {
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(
        `/org/pay`,
        { emailOTP: emailOTP.trim() },
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );

      const authHeader = response.headers.authorization;
      const token = authHeader.split(" ")[1];

      const sessionUpdate = {
        isActive: true,
        authLevel: response.data.authLevel,
        email: response.data.email,
        token: token,
        isVerified: response.data.isVerified,
      };
      handleSignedIn(sessionUpdate);

      setIsShowAuth(false); // DEMO to handle refresh showing a modal
      setIsShowModal(true);
      setModalToShow("showSuccess");
    } catch (error) {
      setAlertType("error");

      if (error.response && error.response.status === 401)
        setAlertText("Invalid OTP.");
      else if (error.response && error.response.status === 429)
        setAlertText("Too many requests from your network!");
      else setAlertText(error.message);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsLoading(false);
  }

  async function resendEmail() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
    };

    try {
      await axiosInstance.post(`/auth/user/resendEmail`, body);
      cdTimer.resetTimer();
      setAlertType("success");
      setAlertText("New OTP successfully sent!");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <main className="main-content  mt-0">
        <div
          className="page-header min-vh-100"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className={isMobile ? "col-12" : "col-4"}>
                <LoadingOverlay active={isLoading} spinner text="Loading...">
                  <div className="card">
                    {alertType && (
                      <Alert
                        alertType={alertType}
                        alertText={alertText}
                        setAlertType={() => setAlertType("")}
                        setAlertText={() => setAlertText("")}
                      />
                    )}
                    <div className="card-body px-lg-5 py-lg-5 text-center">
                      <center>
                        <div className="card-header text-center pt-4">
                          <img src={logo} height="50px" />
                        </div>
                      </center>
                      {renderContent()}
                    </div>
                  </div>
                </LoadingOverlay>
                <div className="col-12">
                  <br />
                  <center>
                    <p className="mb-0">
                      <a
                        href="/notices"
                        className="nav-link text-muted"
                        target="_blank"
                      >
                        <font color="white">
                          TransHives © 2022-
                          {new Date().getFullYear()} | Notices
                        </font>
                      </a>
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {isShowModal && modalToShow === "showSuccess" && (
        <ShowSuccessModal
          header="Registered"
          text="You have successfully completed the registration. Click to go to your dashboard and start having fun!"
          type="withRedirect"
          redirectTo="/dashboard"
        />
      )}
    </>
  );

  function renderContent() {
    if (stepToShow === "step1") {
      return (
        <div className="row">
          <p className="mb-0">
            Welcome to TransHives!
            <br />
            Let's get started. No card needed!
            <br />
            (Step 1 of 2)
          </p>
          <br />
          <br />
          <br /> <br />
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="text"
            name="orgName"
            placeholder="Org Name"
            value={orgName}
            onChange={(value) => setOrgName(value)}
          />
          <Input
            disabled={session.isActive}
            colSize={12}
            mdSize={12}
            type="text"
            name="email"
            placeholder="Work Email Address"
            value={email}
            onChange={(value) => setEmail(value)}
          />
          {!session.isActive && (
            <>
              <Input
                disabled={false}
                colSize={12}
                mdSize={12}
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(value) => setPassword(value)}
              />
              <Input
                disabled={false}
                colSize={12}
                mdSize={12}
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(value) => setConfirmPassword(value)}
              />
            </>
          )}
          <Button
            type={"withBgWithIconRight"}
            colSize={12}
            icon={FaChevronCircleRight}
            text="Continue"
            onClick={() => saveOrg()}
          />
          <Link to="/dashboard">
            <Button
              groupMarginBottom={isMobile ? "1rem" : "0px"}
              type={"noBgNoIcon"}
              colSize={12}
              text="Registered? Let's Sign In!"
              onClick={() => console.log("")}
            />
          </Link>
        </div>
      );
    } else if (stepToShow === "step2") {
      return (
        <div className="row">
          <p className="mb-0">
            Please enter the OTP we sent to {email}
            <br />
            (Step 2 of 2)
          </p>
          <br />
          <br />
          <div class="col-1"></div>
          <Button
            disabled={cdTimer.timeLeft > 0 ? true : false}
            type={"noBgNoIcon"}
            colSize={12}
            text={"Resend Email  (Enabled in: " + cdTimer.formattedTime + ")"}
            onClick={() => resendEmail()}
          />
          <br /> <br />
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="text"
            name="emailOTP"
            placeholder="OTP Code"
            value={emailOTP}
            onChange={(value) => setEmailOTP(value)}
          />
          <Button
            type={"noBgNoIcon"}
            colSize={6}
            text="Back"
            onClick={() => {
              setstepToShow("step1");
              handleSignOut();
            }}
          />
          <Button
            type={"withBgWithIcon"}
            colSize={6}
            icon={FaCheck}
            text="Confirm"
            onClick={() => makePayment()}
          />{" "}
        </div>
      );
    }
  }
}

export default DashboardCreate;
