import React from "react";
import { Link } from "react-router-dom";
import Button from "../../common/button";

function DashboardHelp(props) {
  return (
    <div className="col-lg-4 col-md-12">
      <div className="card h-100">
        <div className="card-header pb-0 p-3">
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">
              <h6 className="mb-0">Guides & Demos</h6>
            </div>
          </div>
        </div>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-6">Check our helpful, attractive guides:</div>
            <div className="col-6">
              <Link to="/help" target="_blank">
                <Button
                  color="primary"
                  marginTop="10px"
                  type={"withBgNoIcon"}
                  colSize={12}
                  text="Go to Help Centre"
                  onClick={() => console.log()}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHelp;
