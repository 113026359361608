import React, { useState } from "react";
import Carousel from "./carousel";

function Collapsible(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleCollapsible = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      <button
        className={`collapsible ${isActive ? "active" : ""}`}
        onClick={toggleCollapsible}
      >
        <h5>{props.title}</h5>
      </button>
      <div className="content" style={{ display: isActive ? "block" : "none" }}>
        <Carousel carouselItems={props.carouselItems} />
      </div>
    </div>
  );
}

export default Collapsible;
