import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import Button from "../../common/button";
import Input from "../../common/input";

function CandidateMoveToModal(props) {
  const options = [
    { value: "new", label: "New" },
    { value: "inReview", label: "In Review" },
    { value: "reserve", label: "Reserve" },
  ];

  const [moveTo, setMoveTo] = useState("");

  useEffect(() => {
    if (props.candidate.stage) {
      if (props.candidate.stage === "new") setMoveTo(options[0]);
      if (props.candidate.stage === "inReview") setMoveTo(options[1]);
      if (props.candidate.stage === "reserve") setMoveTo(options[2]);
    }
  }, []);

  return (
    <Modal show={true} backdrop="static" centered>
      <Modal.Body>
        <div className="row">
          <div className="col-10">
            <h6 className="mb-0">Moving Candidate To...</h6>
          </div>
          <Button
            type={"noBgWithIcon"}
            colSize={2}
            icon={FaTimesCircle}
            text="Close"
            onClick={() => props.closeModal()}
          />
        </div>
        <br />
        <div className="row">
          <Input
            disabled={false}
            colSize={8}
            mdSize={12}
            type="select"
            name="moveTo"
            placeholder="Move to..."
            options={options}
            value={moveTo}
            onChange={(value) => setMoveTo(value)}
          />
          <Button
            type={"withBgWithIcon"}
            colSize={4}
            icon={FaCheckCircle}
            text="Save"
            onClick={() => props.moveCandidate(moveTo)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CandidateMoveToModal;
