import React, { useContext } from "react";
import { FaEye, FaSignOutAlt, FaSignInAlt } from "react-icons/fa";
import { AppContext } from "../../context/appContext";

function Header() {
  const { getSession, handleSignOut, isHideMenu, setIsHideMenu } =
    useContext(AppContext);
  const session = getSession();

  return <>{renderContent()}</>;

  function renderContent() {
    return (
      <div className="container-fluid">
        <div className="row" style={{ marginTop: "20px", marginBottom: "0px" }}>
          <div className="col-6">
            {isHideMenu && (
              <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                    paddingLeft: "0px",
                  }}
                  onClick={() => setIsHideMenu(false)}
                >
                  <FaEye /> View Menu
                </button>
              </p>
            )}
          </div>
          <div className="col-6">
            {session.isActive ? (
              <p className="mb-0" style={{ textAlign: "right" }}>
                Hello, {session.email}
                {session.authLevel <= 2 ? <> (Guest)</> : <></>}
                {"  "}|{"  "}
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                    paddingLeft: "0px",
                  }}
                  onClick={() => handleSignOut()}
                >
                  Sign Out <FaSignOutAlt />
                </button>
              </p>
            ) : (
              <p className="mb-0" style={{ textAlign: "right" }}>
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                    paddingLeft: "0px",
                  }}
                  onClick={() => handleSignOut()}
                >
                  Sign In <FaSignInAlt />
                </button>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
