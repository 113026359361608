import React from "react";

import { SmartH6, SmartP } from "../../common/text";
import { getDate } from "../../../utils/utils";
import Button from "../../common/button";

function ApplicationCard(props) {
  return (
    <div className="col-lg-4 col-md-12" style={{ marginTop: "15px" }}>
      <div className="card h-100">
        <div className="card-header pb-0 p-3">
          <div className="row">
            <div className="col-9">
              <SmartH6
                _id={"jobName-" + props.application._id}
                text={props.application.jobName}
                maxLength={25}
              />
              <SmartP
                _id={"orgName-" + props.application._id}
                text={props.application.orgName}
                maxLength={25}
              />
            </div>
            {/* <Button type={"aArrow"} colSize={3} text={"View"} /> */}
          </div>
        </div>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <p
                className="text-secondary font-weight-bold text-xs mt-1 mb-0"
                style={{ marginBottom: "15px" }}
              >
                Applied On: {getDate(props.application.createdOn)}
              </p>
            </div>
            <div className="col-4" style={{ textAlign: "right" }}>
              {displayStatus(props.application.stage)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function displayStatus(stage) {
    if (stage === "pending") {
      return <span className="badge bg-gradient-info ms-auto">{stage}</span>;
    } else if (stage === "inReview") {
      return <span className="badge bg-gradient-success ms-auto">{stage}</span>;
    } else if (stage === "reserve") {
      return <span className="badge bg-gradient-warning ms-auto">{stage}</span>;
    } else if (stage === "rejected") {
      return <span className="badge bg-gradient-danger ms-auto">{stage}</span>;
    }
  }
}

export default ApplicationCard;
