import React from "react";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle } from "react-icons/fa";

import Button from "../common/button";

function T360LearnMoreModal(props) {
  const navigate = useNavigate();
  return (
    <Modal show={props.showLearnMoreModal} backdrop="static" centered>
      <Modal.Body>
        <div className="row">
          <div className="col-10">
            <h6 className="mb-0">{renderHeaderContent()}</h6>
          </div>
          <Button
            type={"noBgWithIcon"}
            colSize={2}
            icon={FaTimesCircle}
            text="Close"
            onClick={() => props.setShowLearnMoreModal(false)}
          />
        </div>
        <div className="row">
          <div className="col-12">{renderBodyContent()}</div>
        </div>
      </Modal.Body>
    </Modal>
  );

  function renderHeaderContent() {
    if (props.type === "t360") {
      return "About TransHives T360";
    } else if (props.type === "jobCreate") {
      return "Helping you hire the best...";
    } else if (props.type === "sidebar") {
      return "Strategic Talent. Fullest Potential.";
    }
  }

  function renderBodyContent() {
    if (props.type === "t360") {
      return (
        <p className="mb-0">
          TransHives T360 is a short test that tries to better understand you.
          This will help us find opportunities that truly fit you.
          <br />
          <br />
          This is done with a few behavioural questions to understand your fit
          with your future manager and co-workers, followed by a few questions
          to identify your cognitive skills. No preparation is needed. The test
          is not timed and you can take as much time as you need. Just be
          yourself and have fun!
          <br />
          <br /> We care about your privacy! These answers are not shared with
          employers and are only used to calculate a score derived from your
          answers.
        </p>
      );
    } else if (props.type === "jobCreate") {
      return (
        <p className="mb-0">
          When a candidate applies for a job, TransHives sends an email for them
          to complete TransHives T360.
          <br />
          <br />
          This is a short test that evaluates candidates for their objective
          cognitive skills and the subjective fit to their future manager and
          co-workers. In order to evaluate the latter, you can attach a team to
          this job description. No preparation is needed to do the test and they
          can take as much time as needed to complete it.
          <br />
          <br />
          We care about your privacy. These email addresses are not publicly
          shared with the job information. They are only used to send emails to
          the respective team members to answer a few questions that help us
          better understand them. This way, you would have a pre-vetted list to
          start calling for interviews.
        </p>
      );
    } else if (props.type === "sidebar") {
      return (
        <p className="mb-0">
          "HRTech just automate the problems. TransHives solve problems."
          <br />
          <br />
          We believe every person and organisation have what it takes to be
          excellent performers. Just that people need to be at organisations
          they love, doing things they find meaningful, with people they enjoy.
          Just like Albert Einstein said, "Everybody is a genius. But if you
          judge a fish by its ability to climb a tree, it will live its whole
          life believing that it is stupid".
          <br />
          <br />
          Existing HRTech does not allow that to happen. Everyone hates HRTech.
          Long-forms, boring UIs and extreme bureaucracy. All of that for what?
          Just move data here and there and automate problems. Some may claim to
          use AI like ChatGPT but that is just being wrong faster. Result? 80%
          of hires become bad hires, costing 30% of their salary and 10x of that
          in lost synergies.
          <br />
          <br />
          TransHives is fundamentally different. Instead of just automating
          problems, we put CEO-like conviction in the software. Our software
          focuses on understanding candidates and organisations. This allows us
          to spot candidates who are both cognitively sound and way more
          importantly, perfectly fit for the future manager and co-workers. This
          way, candidates can find organisations they will love being at and
          organisations can find talent they will admire having. All of this
          while having a seamless, blissful experience for both candidates and
          HR superheroes.
          <br />
          <br />- TransHives | Let's talk: support@help.transhives.com
        </p>
      );
    } else if (props.type === "jobCredits") {
      return (
        <p className="mb-0">
          When a candidate applies for a job, TransHives sends an email for them
          to complete TransHives T360.
          <br />
          <br />
          This is a short test that evaluates candidates for their objective
          cognitive skills and the subjective fit to their future manager and
          co-workers. In order to evaluate the latter, you can attach a team to
          this job description. No preparation is needed to do the test and they
          can take as much time as needed to complete it.
          <br />
          <br />
          We care about your privacy. These email addresses are not publicly
          shared with the job information. They are only used to send emails to
          the respective team members to answer a few questions that help us
          better understand them. This way, you would have a pre-vetted list to
          start calling for interviews.
        </p>
      );
    }
  }
}

export default T360LearnMoreModal;
