import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { FaArrowAltCircleRight } from "react-icons/fa";

import logo from "../../../assets/img/transhives-logo.png";
import bgImage from "../../../assets/img/curved-images/curved7.jpg";
import Button from "../../common/button";

function T360Intro(props) {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  return (
    <>
      <main className="main-content  mt-0">
        <div
          className="page-header min-vh-100"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className={isMobile ? "col-12" : "col-4"}>
                <div className="card">
                  <div className="card-body px-lg-5 py-lg-5 text-center">
                    <center>
                      <div className="card-header text-center pt-4">
                        <img src={logo} height="50px" />{" "}
                      </div>
                    </center>
                    <div className="row">
                      <p className="mb-0">
                        Welcome to TransHives T360!
                        <br />
                        Be yourself. Take as much time as you need. Have fun!
                        <br /> <br />
                      </p>
                      <Button
                        type={"withBgWithIconRight"}
                        colSize={12}
                        text="Begin T360"
                        icon={FaArrowAltCircleRight}
                        onClick={() => props.setQLevel(1)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default T360Intro;
