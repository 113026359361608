import React from "react";
import { FaArrowRight } from "react-icons/fa";

function Button(props) {
  const [isHovered, setIsHovered] = React.useState(false);
  const { icon: Icon } = props;

  return <>{renderContent()}</>;

  function renderContent() {
    if (props.type === "withBgWithIcon") {
      return (
        <div className={"col-lg-" + props.colSize}>
          <div className={props.formGroup ? "form-group" : ""}>
            <center>
              <button
                className=" btn bg-gradient-dark w-100"
                onClick={() => props.onClick()}
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  padding: "10px",
                }}
              >
                <Icon /> {props.text}
              </button>
            </center>
          </div>
        </div>
      );
    } else if (props.type === "withBgWithIconRight") {
      return (
        <div className={"col-lg-" + props.colSize}>
          <div className="form-group">
            <center>
              <button
                disabled={props.disabled}
                className=" btn bg-gradient-dark w-100"
                onClick={() => props.onClick()}
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  padding: "10px",
                }}
              >
                {props.text} <Icon />
              </button>
            </center>
          </div>
        </div>
      );
    } else if (props.type === "withBgNoIcon") {
      return (
        <div className={"col-lg-" + props.colSize}>
          <div className="form-group">
            <center>
              <button
                className={
                  props.color === "primary"
                    ? "btn bg-gradient-primary w-100"
                    : "btn bg-gradient-dark w-100"
                }
                onClick={() => props.onClick()}
                style={{
                  marginTop: props.marginTop ? props.marginTop : "0px",
                  marginBottom: "0px",
                  padding: "10px",
                }}
              >
                {props.text}
              </button>
            </center>
          </div>
        </div>
      );
    } else if (props.type === "noBgWithIcon") {
      return (
        <div className={"col-lg-" + props.colSize}>
          <center>
            <button
              disabled={props.disabled}
              className="btn btn-link  w-90"
              onClick={() => props.onClick()}
              style={{ marginTop: "5px", padding: "0px" }}
            >
              <Icon /> {props.text}
            </button>
          </center>
        </div>
      );
    } else if (props.type === "noBgNoIcon") {
      return (
        <div className={"col-lg-" + props.colSize}>
          <div
            className="form-group"
            style={{
              marginBottom: props.groupMarginBottom
                ? props.groupMarginBottom
                : "1rem",
            }}
          >
            <center>
              <button
                disabled={props.disabled}
                className="btn btn-link  w-90"
                onClick={() => props.onClick()}
                style={{
                  marginTop: props.marginTop ? props.marginTop : "11px",
                  marginBottom: "0px",
                  padding: "0px",
                }}
              >
                {props.text}
              </button>
            </center>
          </div>
        </div>
      );
    } else if (props.type === "aArrow") {
      return (
        <div className={"col-" + props.colSize} style={{ paddingRight: "0px" }}>
          <a
            className="text-sm font-weight-bold mb-0 icon-move-right mt-auto"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <button
              disabled={props.disabled}
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                paddingLeft: "0px",
                marginTop: props.marginTop,
                color: props.color ? props.color : "",
              }}
              onClick={() => props.onClick()}
            >
              {props.text}
              <FaArrowRight
                className={`text-sm ms-1 ${isHovered ? "hovered" : ""}`}
                aria-hidden="true"
                style={{
                  transition: "transform 0.2s ease",
                  transform: isHovered ? "translateX(5px)" : "none",
                }}
              />
            </button>
          </a>
        </div>
      );
    } else if (props.type === "bInline") {
      return (
        <div className={"col-lg-" + props.colSize}>
          <center>
            <button
              disabled={props.disabled}
              className="btn btn-link  w-90"
              onClick={() => props.onClick()}
              style={{
                marginBottom: "0px",
                padding: "0px",
              }}
            >
              {props.text}
            </button>
          </center>
        </div>
      );
    }
  }
}

export default Button;
