import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  FaLaptop,
  FaPencilAlt,
  FaBriefcase,
  FaPlusCircle,
  FaUsers,
  FaEyeSlash,
} from "react-icons/fa";
import { AppContext } from "../../context/appContext";

import LearnMoreModal from "../modals/learnMoreModal";

import logo1 from "../../assets/img/transhives-logo.png";
import logo2 from "../../assets/img/favicon.png";
import bgImage from "../../assets/img/curved-images/curved-10.jpg";
import { SmartP } from "../common/text";
import Button from "../common/button";

function OrgSidebar() {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const { isHideMenu, setIsHideMenu, currentPageID } = useContext(AppContext);
  const [sideBarHeight, setSideBarHeight] = useState("calc(100vh - 360px)");
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  useEffect(() => {
    if (isMobile) setSideBarHeight("calc(100vh - 375px)");
  }, []);

  return <>{renderContent()}</>;

  function renderContent() {
    if (!isHideMenu) {
      return (
        <>
          <aside
            className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white"
            id="sidenav-main"
          >
            <center>
              <a className="navbar-brand m-0" target="_blank">
                <img src={logo1} className="navbar-brand-img" height="100%" />

                {isMobile && (
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        paddingLeft: "0px",
                      }}
                      onClick={() => setIsHideMenu(true)}
                    >
                      <FaEyeSlash /> Hide Menu
                    </button>
                  </p>
                )}
              </a>
            </center>

            <hr className="horizontal dark mt-0" />
            <div
              className="collapse navbar-collapse  w-auto "
              id="sidenav-collapse-main"
              style={{ height: sideBarHeight }}
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/dashboard">
                    <div
                      style={{
                        backgroundColor:
                          currentPageID === "dashboard" ? "#f5f5fc" : "",
                      }}
                    >
                      <a className="nav-link">
                        <div className="icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                          <FaLaptop />
                        </div>
                        <span className="nav-link-text ms-1">Dashboard</span>
                      </a>{" "}
                    </div>
                  </Link>
                </li>
                <br />
                <li className="nav-item">
                  <Link to="/jobs/create">
                    <div
                      style={{
                        backgroundColor:
                          currentPageID === "jobCreate" ? "#f5f5fc" : "",
                      }}
                    >
                      <a className="nav-link">
                        <div className="icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                          <FaPencilAlt />
                        </div>
                        <span className="nav-link-text ms-1">Create a Job</span>
                      </a>{" "}
                    </div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/jobs">
                    <div
                      style={{
                        backgroundColor:
                          currentPageID === "viewJobs" ? "#f5f5fc" : "",
                      }}
                    >
                      <a className="nav-link">
                        <div className="icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                          <FaBriefcase />
                        </div>
                        <span className="nav-link-text ms-1">
                          View Jobs/Candidates
                        </span>
                      </a>{" "}
                    </div>
                  </Link>
                </li>
                <br />
                <li className="nav-item">
                  <Link to="/teams/create">
                    <div
                      style={{
                        backgroundColor:
                          currentPageID === "teamCreate" ? "#f5f5fc" : "",
                      }}
                    >
                      <a className="nav-link">
                        <div className="icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                          <FaPlusCircle />
                        </div>
                        <span className="nav-link-text ms-1">
                          Create a Team
                        </span>
                      </a>
                    </div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/teams">
                    <div
                      style={{
                        backgroundColor:
                          currentPageID === "viewTeams" ? "#f5f5fc" : "",
                      }}
                    >
                      <a className="nav-link">
                        <div className="icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                          <FaUsers />
                        </div>
                        <span className="nav-link-text ms-1">View Teams</span>
                      </a>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidenav-footer mx-3">
              <div
                className="card card-background shadow-none card-background-mask-secondary"
                id="sidenavCard"
              >
                <div
                  className="full-background"
                  style={{ backgroundImage: `url(${bgImage})` }}
                ></div>
                <div className="card-body text-start p-3 w-100">
                  <img src={logo2} height="50px" />
                  <div className="docs-info">
                    <h6 className="text-white up mb-0">Powered By</h6>
                    <p className="text-xs font-weight-bold">
                      <b>TransHives</b>: CEO-like conviction built-in to hire
                      strategic talent and realise the synergies of them working
                      together.
                    </p>
                    <Link to="/help" target="_blank">
                      <Button
                        color="white"
                        type={"aArrow"}
                        colSize={12}
                        text="Help Centre"
                        onClick={() => console.log("")}
                        // onClick={() => setShowLearnMoreModal(true)}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </aside>
          {showLearnMoreModal && (
            <LearnMoreModal
              type="sidebar"
              showLearnMoreModal={showLearnMoreModal}
              setShowLearnMoreModal={() => setShowLearnMoreModal(false)}
            />
          )}
        </>
      );
    }
  }
}

export default OrgSidebar;
