import React, { useState, useEffect } from "react";

import { FaTimesCircle } from "react-icons/fa";

function Alert(props) {
  const [showAlert, setShowAlert] = useState(true);
  let timeout;

  useEffect(() => {
    timeout = setTimeout(() => {
      closeAlert();
    }, 7000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  function closeAlert() {
    clearTimeout(timeout);
    props.setAlertType();
    props.setAlertText();
    setShowAlert(false);
  }

  return <>{showAlert && displayContent()}</>;

  function displayContent() {
    if (props.alertType === "success") {
      return (
        <div className="container-fluid">
          <br />
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <font color="#FFFFFF">
              <strong>Success!</strong> {props.alertText}{" "}
            </font>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              style={{ paddingTop: "15px" }}
              onClick={() => closeAlert()}
            >
              <FaTimesCircle />
            </button>
          </div>
        </div>
      );
    } else if (props.alertType === "info") {
      return (
        <div className="container-fluid">
          <br />
          <div
            className="alert alert-info alert-dismissible fade show"
            role="alert"
          >
            <font color="#FFFFFF">
              <strong>Info: </strong> {props.alertText}{" "}
            </font>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              style={{ paddingTop: "15px" }}
              onClick={() => closeAlert()}
            >
              <FaTimesCircle />
            </button>
          </div>
        </div>
      );
    } else if (props.alertType === "error") {
      return (
        <div className="container-fluid">
          <br />

          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <font color="#FFFFFF">
              <strong>Error!</strong> {props.alertText}
            </font>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              style={{ paddingTop: "15px" }}
              onClick={() => closeAlert()}
            >
              <FaTimesCircle />
            </button>
          </div>
        </div>
      );
    }
  }
}

export default Alert;
