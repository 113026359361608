import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/high-res.css";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import { FaChevronCircleRight } from "react-icons/fa";

import logo from "../../../assets/img/transhives-logo.png";
import bgImage from "../../../assets/img/curved-images/curved7.jpg";
import Button from "../../common/button";

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    document.title = "Home | TransHives";
  }, []);

  return (
    <main className="main-content  mt-0">
      <div
        className="page-header min-vh-100"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container">
          <div className="row justify-content-center">
            <div className={isMobile ? "col-12" : "col-4"}>
              <LoadingOverlay active={isLoading} spinner text="Loading...">
                <div className="card">
                  <div className="card-body px-lg-5 py-lg-5 text-center">
                    <center>
                      <div className="card-header text-center pt-4">
                        <img src={logo} height="50px" />{" "}
                      </div>
                    </center>
                    <div className="row">
                      <p className="mb-0">
                        Welcome to TransHives!
                        <br />
                        <br />
                        <Link to="/dashboard">
                          <Button
                            type={"withBgWithIconRight"}
                            colSize={12}
                            icon={FaChevronCircleRight}
                            text="I represent an organisation"
                            onClick={() => console.log("")}
                          />
                        </Link>
                        <Link to="/profile">
                          <Button
                            type={"withBgWithIconRight"}
                            colSize={12}
                            icon={FaChevronCircleRight}
                            text="I am a candidate/an employee"
                            onClick={() => console.log("")}
                          />{" "}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
              <div className="col-12">
                <br />
                <center>
                  <p className="mb-0">
                    <a
                      href="/notices"
                      className="nav-link text-muted"
                      target="_blank"
                    >
                      <font color="white">
                        TransHives © 2022-
                        {new Date().getFullYear()} | Notices
                      </font>
                    </a>
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
