import React from "react";
import Input from "../../common/input";

function T360CAttempt(props) {
  return (
    <>
      <Input
        disabled={false}
        colSize={12}
        type="number"
        name="answer"
        placeholder="Answer"
        value={props.cAnswer}
        onChange={(value) => props.setCAnswer(value)}
      />
      <br />
      <Input
        disabled={false}
        colSize={12}
        mdSize={12}
        rows={5}
        type="textArea"
        name="notes"
        placeholder="How did you arrive at it? What was your approach?"
        value={props.notes}
        onChange={(value) => props.setNotes(value)}
      />
    </>
  );
}

export default T360CAttempt;
