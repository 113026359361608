import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axiosConfig";
import LoadingOverlay from "react-loading-overlay";

import { AppContext } from "../../../context/appContext";
import Auth from "../../auth/auth";

import OrgSidebar from "../../layout/orgSidebar";
import Header from "../../layout/header";
import PageTitle from "../../layout/pageTitle";
import Footer from "../../layout/footer";
import Alert from "../../common/alert";
import LoadMore from "../../common/loadMore";

import CandidateFilters from "./candidadeFilters";
import CandidateCard from "./candidateCard";
import CandidateInfoModal from "./candidateInfoModal";
import CandidateAddModal from "../../modals/canAddModal";

function Candidates() {
  const {
    getSession,
    isShowAuth,
    setCurrentPageID,
    setIsShowAuth,
    handleSignOut,
  } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [isAllRecords, setIsAllRecords] = useState(false);
  const [page, setPage] = useState(1);

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowCanAddModal, setIsShowCanAddModal] = useState(false);

  const [indexOfCandidate, setIndexOfCandidate] = useState("");
  const [candidate, setCandidate] = useState({});
  const [candidates, setCandidates] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const jobID = urlParams.get("jobID");

  const [jobName, setJobName] = useState("");

  const [candidateName, setCandidateName] = useState("");
  const [stage, setStage] = useState("");
  const [orderBy, setOrderBy] = useState({ value: "tScore", label: "TScore" });

  useEffect(() => {
    setCurrentPageID("viewCandidates");
    if (session.authLevel >= 3) {
      fetchData(session, candidateName, stage, orderBy, page);
    } else setIsShowAuth(true);
  }, []);

  async function fetchData(session, candidateName, stage, orderBy, page) {
    setIsLoading(true);
    setIsAllRecords(false);

    const queryParams = {
      candidateName: candidateName,
      stage: stage,
      orderBy: orderBy,
      page: page,
      showIncomplete: true,
    };

    try {
      const response = await axiosInstance.get(`/candidate/org/${jobID}`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
        params: queryParams,
      });

      updateIsAllRecords(response.data, candidates);

      if (response.data[0] && response.data[0].jobName) {
        setJobName(response.data[0].jobName);
        document.title =
          "Candidates for " + response.data[0].jobName + " | TransHives";
      }

      setCandidates(response.data);
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  function updateIsAllRecords(incomingArray, existingArray) {
    if (incomingArray.length % 9 !== 0) {
      setIsAllRecords(true);
    } else {
      const sortedArr1 = incomingArray.slice().sort();
      const sortedArr2 = existingArray.slice().sort();
      if (JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)) {
        setIsAllRecords(true);
      }
    }
  }

  function applyFilters() {
    setCandidates([]);
    fetchData(session, candidateName, stage, orderBy, page, 1);
    setPage(1);
  }

  function resetFilters() {
    setCandidates([]);
    setCandidateName("");
    setStage(null);
    setOrderBy(null);
    fetchData(session, null, null, null, 1);
    setPage(1);
  }

  function loadMore() {
    fetchData(session, candidateName, stage, orderBy, page + 1);
    setPage(page + 1);
  }

  function viewCandidate(candidate, index) {
    setCandidate(candidate);
    setIndexOfCandidate(index);
    setIsShowModal(true);
  }

  async function updateScores() {}

  return (
    <>
      <OrgSidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        {alertType && (
          <Alert
            alertType={alertType}
            alertText={alertText}
            setAlertType={() => setAlertType(null)}
            setAlertText={() => setAlertText(null)}
          />
        )}
        <Header />
        {session.authLevel >= 3 && (
          <>
            <LoadingOverlay active={isLoading} spinner text="Loading...">
              <div className="container-fluid py-4">
                <PageTitle
                  title={"Candidates, " + jobName}
                  helpText="Below are the candidates who applied. Filter, manage and select candidates from this page."
                  actionButton={true}
                  actionButtonText={"Add Candidates"}
                  actionButtonClicked={() => setIsShowCanAddModal(true)}
                />
                <CandidateFilters
                  candidateName={candidateName}
                  stage={stage}
                  orderBy={orderBy}
                  setCandidateName={(value) => setCandidateName(value)}
                  setStage={(value) => setStage(value)}
                  setOrderBy={(value) => setOrderBy(value)}
                  applyFilters={() => applyFilters()}
                  resetFilters={() => resetFilters()}
                />

                <div className="row">
                  {candidates.map((candidate, index) => (
                    <CandidateCard
                      key={candidate._id}
                      candidate={candidate}
                      viewCandidate={() => viewCandidate(candidate, index)}
                    />
                  ))}
                </div>
                <LoadMore
                  isAllRecords={isAllRecords}
                  loadMore={() => loadMore()}
                />
              </div>
            </LoadingOverlay>
            <Footer />
          </>
        )}
      </main>
      {isShowModal && (
        <CandidateInfoModal
          candidate={candidate}
          closeModal={() => setIsShowModal(false)}
          moveCandidate={(moveTo) =>
            moveCandidate(candidate, indexOfCandidate, moveTo)
          }
        />
      )}
      {isShowAuth && (
        <Auth
          isFromApply={false}
          onAuth={(session) =>
            fetchData(session, candidateName, stage, orderBy, page)
          }
        />
      )}

      {isShowCanAddModal && (
        <CandidateAddModal
          isVerified={session.isVerified}
          jobID={jobID}
          candidatesAdded={(value) => candidatesAdded(value)}
          closeModal={() => setIsShowCanAddModal(false)}
          setAlertType={(value) => setAlertType(value)}
          setAlertText={(value) => setAlertText(value)}
        />
      )}
    </>
  );

  async function candidatesAdded(canAddedArray) {
    const tempArray = canAddedArray.concat(candidates);
    setCandidates(tempArray);
  }

  async function moveCandidate(candidate, indexOfCandidate, moveTo) {
    setIsLoading(true);

    const body = {
      moveTo: moveTo,
      myNotes: "",
    };

    try {
      await axiosInstance.put(
        `/candidate/org/moveCandidate/${candidate._id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );

      let array = [...candidates];
      array[indexOfCandidate].stage = moveTo.value;
      setCandidates(array);

      setAlertType("success");
      setAlertText("Candidate successfully moved to " + moveTo.label);
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsShowModal(false);
    setIsLoading(false);
  }
}

export default Candidates;
