import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import axiosInstance from "../../../utils/axiosConfig";
import LoadingOverlay from "react-loading-overlay";
import { AppContext } from "../../../context/appContext";

import Sidebar from "../../layout/sidebar";
import Footer from "../../layout/footer";
import Alert from "../../common/alert";
import LoadMore from "../../common/loadMore";

import CareerPageHeader from "./careerPageHeader";
import CarrerPageFilters from "./carrerPageFilters";
import CarrerPageCard from "./carrerPageCard";
import CareerPageModals from "./careerPageModals";
import Header from "../../layout/header";

function CareerPage() {
  const { getSession, setRecentlyViewed, setCurrentPageID } =
    useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [isAllRecords, setIsAllRecords] = useState(false);
  const [page, setPage] = useState(1);

  const [eduOptions, setEduOptions] = useState([]);
  const [expOptions, setExpOptions] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalToShow, setModalToShow] = useState("viewJob");
  const [job, setJob] = useState({});

  const [org, setOrg] = useState({ orgName: "" });
  const [jobs, setJobs] = useState([]);

  const { linkID } = useParams();
  const location = useLocation();
  const [jobName, setJobName] = useState("");
  const [minEdu, setMinEdu] = useState("");
  const [minExp, setMinExp] = useState("");
  const [paramJobID, setParamJobID] = useState("");

  useEffect(() => {
    setCurrentPageID("careerPage");

    const incomingJobID = new URLSearchParams(location.search).get("jobID");
    if (incomingJobID) setParamJobID(incomingJobID);

    fetchData(jobName, minEdu, minExp, page, incomingJobID);
  }, []);

  async function fetchData(jobName, minEdu, minExp, page, paramJobID) {
    setIsLoading(true);
    setIsAllRecords(false);

    const queryParams = {
      jobName: jobName,
      minEdu: minEdu,
      minExp: minExp,
      page: page,
      paramJobID: paramJobID,
    };

    try {
      const response = await axiosInstance.get(`/job/user/org/${linkID}`, {
        params: queryParams,
      });

      updateIsAllRecords(response.data.jobs, jobs);

      setOrg(response.data.org);
      setJobs(response.data.jobs);
      setRecentlyViewed({ linkID: linkID, orgName: response.data.org.orgName });

      document.title =
        "Careers at " + response.data.org.orgName + " | TransHives";

      setEduOptions(response.data.eduOptions);
      setExpOptions(response.data.expOptions);

      if (response.data.paramJob) {
        viewJob(response.data.paramJob);
        setParamJobID("");
      }

      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      setIsLoading(false);
    }
  }

  function updateIsAllRecords(incomingArray, existingArray) {
    if (incomingArray.length % 9 !== 0) {
      setIsAllRecords(true);
    } else {
      const sortedArr1 = incomingArray.slice().sort();
      const sortedArr2 = existingArray.slice().sort();
      if (JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)) {
        setIsAllRecords(true);
      }
    }
  }

  function applyFilters() {
    setJobs([]);
    fetchData(jobName, minEdu, minExp, 1, paramJobID);
    setPage(1);
  }

  function resetFilters() {
    setJobs([]);
    setJobName("");
    setMinEdu(null);
    setMinExp(null);
    fetchData(null, null, null, 1, paramJobID);
    setPage(1);
  }

  function loadMore() {
    fetchData(jobName, minEdu, minExp, page + 1, paramJobID);
    setPage(page + 1);
  }

  function viewJob(job) {
    setJob(job);
    setModalToShow("viewJob");
    setIsShowModal(true);
  }

  return (
    <>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        {alertType && (
          <Alert
            alertType={alertType}
            alertText={alertText}
            setAlertType={() => setAlertType(null)}
            setAlertText={() => setAlertText(null)}
          />
        )}

        {session.isActive && <Header />}
        <LoadingOverlay active={isLoading} spinner text="Loading...">
          <CareerPageHeader org={org} />
          <div className="container-fluid py-4">
            <CarrerPageFilters
              eduOptions={eduOptions}
              expOptions={expOptions}
              jobName={jobName}
              minEdu={minEdu}
              minExp={minExp}
              setJobName={(value) => setJobName(value)}
              setMinEdu={(value) => setMinEdu(value)}
              setMinExp={(value) => setMinExp(value)}
              applyFilters={() => applyFilters()}
              resetFilters={() => resetFilters()}
            />

            <hr className="horizontal dark" />

            <div className="row">
              {jobs.map((job) => (
                <CarrerPageCard
                  key={job._id}
                  job={job}
                  viewJob={() => viewJob(job)}
                />
              ))}
            </div>
            <LoadMore isAllRecords={isAllRecords} loadMore={() => loadMore()} />
          </div>
        </LoadingOverlay>

        {isShowModal && (
          <CareerPageModals
            modalToShow={modalToShow}
            closeModal={() => setIsShowModal(false)}
            setModalToShow={(value) => setModalToShow(value)}
            job={job}
          />
        )}

        <Footer />
      </main>
    </>
  );
}

export default CareerPage;
