import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState({
    isActive: false,
    authLevel: 0,
    token: "",
    email: "",
    resumeName: "",
    isVerified: false,
  });

  const handleSignedIn = (session) => {
    let sessionToAssign = {};

    if (session.isActive) sessionToAssign.isActive = session.isActive;
    if (session.authLevel) sessionToAssign.authLevel = session.authLevel;
    if (session.token) sessionToAssign.token = session.token;
    if (session.email) sessionToAssign.email = session.email;
    if (session.resumeName) sessionToAssign.resumeName = session.resumeName;
    if (session.isVerified) sessionToAssign.isVerified = session.isVerified;

    localStorage.setItem("session", JSON.stringify(sessionToAssign));
    setSession(sessionToAssign);
  };

  const handleSignOut = () => {
    localStorage.removeItem("session");
    setSession({
      isActive: false,
      authLevel: 0,
      token: "",
      email: "",
      resumeName: "",
      isVerified: false,
    });

    if (currentPageID !== "careerPage") {
      setIsShowAuth(true);
    }
  };

  const getSession = () => {
    if (session.isActive) {
      return session;
    } else {
      const localSession = localStorage.getItem("session");

      if (localSession) {
        const jsonLocalSession = JSON.parse(localSession);
        handleSignedIn(jsonLocalSession);
        return jsonLocalSession;
      } else {
        return session;
      }
    }
  };

  const [isHideMenu, setIsHideMenu] = useState(false);
  const [isShowAuth, setIsShowAuth] = useState(false);
  const [recentlyViewed, setRecentlyViewed] = useState({});
  const [currentPageID, setCurrentPageID] = useState("");

  return (
    <AppContext.Provider
      value={{
        getSession,
        handleSignedIn,
        handleSignOut,

        isHideMenu,
        setIsHideMenu,
        isShowAuth,
        setIsShowAuth,
        recentlyViewed,
        setRecentlyViewed,
        currentPageID,
        setCurrentPageID,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
