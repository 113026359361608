import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Button from "../../common/button";
import TeamInputsModal from "./teamInputsModal";

function TeamInfoModal(props) {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <Modal show={true} backdrop="static" size="lg" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-8">
              <h4 className="mb-0">{props.team.teamName}</h4>
            </div>
            <Button
              type={"aArrow"}
              colSize={2}
              text={"Edit Team"}
              onClick={() => setIsShowModal(true)}
            />
            <Button
              type={"noBgWithIcon"}
              colSize={2}
              icon={FaTimesCircle}
              text="Close"
              onClick={() => props.closeModal()}
            />
          </div>

          <div className="row">
            {/*
             <div className="col-3">
              <p className="mb-0">
                <br /> <br />
                Strategic Fit <br /> 
                 <Button
                  type={"aArrow"}
                  colSize={12}
                  text={"Recommendations"}
                  onClick={() => setIsShowModal(true)}
                /> 
              </p>
            </div>
            <div className="col-2">
              <br />
              <CircularProgressbar
                value={90}
                text={`${90}%`}
                styles={buildStyles({
                  pathColor: `rgba(33, 212, 253)`,
                  textColor: "#000",
                  trailColor: "#d6d6d6",
                })}
              /> 
              <br />
              <br />
            </div>
            <div className="col-1"></div>            
            */}
            <div className="col-6">
              <br />
              <p className="mb-0">
                <b>Leaders</b>
              </p>
              {props.team.leadersArray.map((leader) => (
                <>
                  <div className="row">
                    <div className="col-8">{leader.workEmail}</div>
                    <div className="col-3">{displayStatus(leader.status)}</div>
                  </div>
                </>
              ))}
              <br />
            </div>
            <div className="col-6">
              <br />
              <p className="mb-0">
                <b>Members</b>
              </p>
              {props.team.membersArray.map((member) => (
                <div className="row">
                  <div className="col-8">{member.workEmail}</div>
                  <div className="col-3">{displayStatus(member.status)}</div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {isShowModal && (
        <TeamInputsModal
          type="update"
          team={props.team}
          teamUpdated={(value) => {
            props.closeModal();
            props.teamUpdated(value);
          }}
          setAlertType={(value) => props.setAlertType(value)}
          setAlertText={(value) => props.setAlertText(value)}
          closeModal={() => setIsShowModal(false)}
        />
      )}
    </>
  );

  function displayStatus(status) {
    if (status === "accepted") {
      return (
        <span className="badge bg-gradient-success ms-auto">{status}</span>
      );
    } else if (status === "pending") {
      return (
        <span className="badge bg-gradient-warning ms-auto">{status}</span>
      );
    }
  }
}

export default TeamInfoModal;
