import React from "react";

function LoadMore(props) {
  if (props.isAllRecords) {
    return (
      <div className="row">
        <div className="col-12">
          <br />
          <center>
            <button
              disabled={true}
              type="button"
              className="btn btn-secondary btn-sm"
            >
              <font color="#FFFFFF">All Records Displayed!</font>
            </button>
          </center>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        <div className="col-12">
          <br />
          <center>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => props.loadMore()}
            >
              Load More
            </button>
          </center>
        </div>
      </div>
    );
  }
}

export default LoadMore;
