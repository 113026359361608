import React, { useState } from "react";

import JobInfoModal from "../../modals/jobInfoModal";
import ApplyToJobModal from "../../modals/applyToJobModal";
import ShowSuccessModal from "../../modals/showSuccessModal";

function CareerPageModals(props) {
  return <> {displayContent()}</>;

  function displayContent() {
    if (props.modalToShow === "viewJob") {
      return (
        <JobInfoModal
          type="careerPage"
          job={props.job}
          closeModal={() => props.closeModal()}
          setModalToShow={(value) => props.setModalToShow(value)}
        />
      );
    } else if (props.modalToShow === "applyToJob") {
      return (
        <ApplyToJobModal
          job={props.job}
          closeModal={() => props.closeModal()}
          setModalToShow={(value) => props.setModalToShow(value)}
        />
      );
    } else if (props.modalToShow === "showSuccess") {
      return (
        <ShowSuccessModal
          header="Applied"
          text="Congratulations. You successfully applied to this job. We wish you all the best.
          You can close this pane and explore other jobs."
          type="withClose"
          closeModal={() => props.closeModal()}
        />
      );
    }
  }
}

export default CareerPageModals;
