import React, { useState } from "react";
import { useNavigate } from "react-router";
import LearnMoreModal from "../../modals/learnMoreModal";

import { getDateTime } from "../../../utils/utils";
import Button from "../../common/button";
import { Link } from "react-router-dom";

function ProfileT360Info(props) {
  const navigate = useNavigate();
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  return (
    <>
      <div className="col-lg-4 col-md-12">
        <div className="card h-100">
          <div className="card-header pb-0 p-3">
            <div className="row">
              <div className="col-md-8 d-flex align-items-center">
                <h6 className="mb-0">T360 Information</h6>
              </div>
              <div className="col-md-4 text-end"></div>
            </div>
          </div>
          <div className="card-body p-3">{renderContent()}</div>
        </div>
      </div>
      <LearnMoreModal
        type="t360"
        showLearnMoreModal={showLearnMoreModal}
        setShowLearnMoreModal={() => setShowLearnMoreModal(false)}
      />
    </>
  );

  function renderContent() {
    if (props.user.t360CompletedOn) {
      return (
        <>
          <div className="row">
            <div className="col-4">Completed: </div>
            <div className="col-8">
              {getDateTime(props.user.t360CompletedOn)}
            </div>
            <div className="col-4">Status: </div>
            <div className="col-8">
              <span className="badge bg-gradient-info ms-auto">Completed</span>
            </div>
          </div>

          <div className="row">
            <Button
              disabled={true}
              type={"aArrow"}
              colSize={6}
              text="Start Now"
              onClick={() => navigate("/")}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="row">
            <div className="col-4">Scheduled: </div>
            <div className="col-8">
              {getDateTime(props.user.t360ScheduledFor)}
            </div>
            <div className="col-4">Status: </div>
            <div className="col-8">
              <span className="badge bg-gradient-info ms-auto">Pending</span>
            </div>
          </div>

          <div className="row">
            {/* <Button
              type={"aArrow"}
              colSize={6}
              text="Learn More"
              onClick={() => setShowLearnMoreModal(true)}
            /> */}
            <Button
              type={"aArrow"}
              colSize={6}
              text="Start Now"
              onClick={() => navigate("/t360")}
            />
          </div>
        </>
      );
    }
  }
}

export default ProfileT360Info;
