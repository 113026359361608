import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Button from "../../common/button";
import CandidateMoveToModal from "./candidateMoveToModal";

import DetailedAnalysisBanner from "./detailedAnalysisBanner.webp";

function CandidateInfoModal(props) {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        size="lg"
        dialogClassName="customModalWidth"
        centered
      >
        <Modal.Body>
          <div className="row">
            <div className="col-7"></div>
            <div className="col-5">
              <div className="row">
                <div className="col-5"></div>
                <Button
                  type={"aArrow"}
                  colSize={5}
                  text={"Move Candidate To"}
                  onClick={() => setIsShowModal(true)}
                />
                <Button
                  type={"noBgWithIcon"}
                  colSize={2}
                  icon={FaTimesCircle}
                  text="Close"
                  onClick={() => props.closeModal()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-8">
                  <h5 className="mb-0">
                    {displayCandidateName(props.candidate)}
                  </h5>
                  <p className="mb-0">{props.candidate.email}</p>
                  <br />
                </div>
                <div className="col-4">
                  {displayStatus(props.candidate.stage)}
                </div>
                <div className="col-2">
                  <p className="mb-0">
                    <b>Score:</b>
                  </p>
                  <p className="mb-0">
                    {displayTScore(props.candidate.tScore)}
                  </p>
                </div>
                <div className="col-10">
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    This score is calculated factoring in both objective
                    cognitive skills and unique fit to the future manager and
                    team members. As we train our models with more real data,
                    the accuracy of this score will improve over time to bring
                    more value to you.
                  </p>
                  <br />
                </div>
                <div className="col-12">
                  <p className="mb-0">
                    <b>Why Me?</b>
                  </p>
                  <p className="mb-0">{displayWhyMe(props.candidate.whyMe)}</p>
                  <br />
                  <img
                    src={DetailedAnalysisBanner}
                    loading="lazy"
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      border: "1px solid #191970",
                    }}
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              {props.candidate && props.candidate.resumePath && (
                <iframe
                  src={
                    "https://s3.eu-west-2.amazonaws.com/app.transhives.com/resumes/" +
                    props.candidate.resumePath
                  }
                  title="PDF Viewer"
                  width="100%"
                  height="100%"
                ></iframe>
              )}
              {props.candidate &&
                !props.candidate.resumePath &&
                "No resume was provided."}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {isShowModal && (
        <CandidateMoveToModal
          candidate={props.candidate}
          closeModal={() => setIsShowModal(false)}
          moveCandidate={(value) => props.moveCandidate(value)}
        />
      )}
    </>
  );

  function displayTScore(tScore) {
    const tScoreAsPercentage = tScore * 100;
    if (tScoreAsPercentage < 15.1) return "Pending";
    else return tScoreAsPercentage.toFixed(2);
  }

  function displayWhyMe(whyMe) {
    if (whyMe === "Added by Org User")
      return "Not applicable. Added by Org User.";
    else return whyMe;
  }

  function displayStatus(status) {
    if (status === "new") {
      return <span className="badge bg-gradient-info ms-auto">{status}</span>;
    } else if (status === "inReview") {
      return (
        <span className="badge bg-gradient-success ms-auto">{status}</span>
      );
    } else if (status === "reserve") {
      return (
        <span className="badge bg-gradient-warning ms-auto">{status}</span>
      );
    } else if (status === "rejected") {
      return <span className="badge bg-gradient-danger ms-auto">{status}</span>;
    }
  }

  function displayCandidateName(candidate) {
    if (candidate.firstName || candidate.lastName)
      return props.candidate.firstName + " " + props.candidate.lastName;
    else return "T360 Pending...";
  }
}

export default CandidateInfoModal;
