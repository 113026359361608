import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/appContext";
import { FaStar } from "react-icons/fa";

import Footer from "../../layout/footer";
import PageTitle from "../../layout/pageTitle";
import HelpSidebar from "../../layout/helpSidebar";
import Collapsible from "./collapsible";

import JobCreate1 from "./screenshots/jobCreate1.webp";
import JobCreate2 from "./screenshots/jobCreate2.webp";
import JobCreate3 from "./screenshots/jobCreate3.webp";
import JobCreate4 from "./screenshots/jobCreate4.webp";

import JobView1 from "./screenshots/jobView1.webp";
import JobView2 from "./screenshots/jobView2.webp";
import JobView3 from "./screenshots/jobView3.webp";

import CandidateView3 from "./screenshots/candidateView3.webp";
import CandidateView4 from "./screenshots/candidateView4.webp";

import CanApply1 from "./screenshots/canApply1.webp";
import CanApply2 from "./screenshots/canApply2.webp";
import CanApply3 from "./screenshots/canApply3.webp";
import CanApply4 from "./screenshots/canApply4.webp";

import CandidateAdd4 from "./screenshots/candidateAdd4.webp";
import CandidateAdd5 from "./screenshots/candidateAdd5.webp";

import T3601 from "./screenshots/t3601.webp";
import T3602 from "./screenshots/t3602.webp";
import T3603 from "./screenshots/t3603.webp";
import T3604 from "./screenshots/t3604.webp";

function Help() {
  const { setCurrentPageID } = useContext(AppContext);

  useEffect(() => {
    setCurrentPageID("help");
    document.title = "Help Centre | TransHives";
  }, []);

  const carouselItems = {
    jobCreate: [
      {
        src: JobCreate1,
        title: "Step 1",
        content: (
          <p>Click 'Create a Job' and enter the information requested.</p>
        ),
      },
      {
        src: JobCreate2,
        title: "Step 2",
        content: (
          <p style={{ textAlign: "justify" }}>
            Click 'Add Team' to attach a team for this job.
            <br />
            <br />
            Why? When you add a team, we will email the team members invitations
            to complete T360. This way, when a candidate applies to this job, we
            can compare their answers with the team's answers to perform a
            tailored behavioural evaluation - to find the unique fit for the
            future manager and team members.
          </p>
        ),
      },
      {
        src: JobCreate3,
        title: "Step 3",
        content: (
          <p>
            Enter the team emails (separate them with commas). Click 'Create
            Team'. Don't worry, you can always edit these later from the 'View
            Teams' tab.
          </p>
        ),
      },
      {
        src: JobCreate4,
        title: "Step 4",
        content: (
          <p style={{ textAlign: "justify" }}>
            Click 'Create Job'. Congrats! Now, you can start receiving
            applications for this job. Check out the below guides on sharing the
            job URL and managing candidates. Have fun!
          </p>
        ),
      },
    ],
    jobView: [
      {
        src: JobView1,
        title: "Step 1",
        content: <p>Click 'View Jobs'.</p>,
      },
      {
        src: JobView2,
        title: "Step 2",
        content: (
          <p>Click 'View &rarr;' on the job you wish to share the link of.</p>
        ),
      },
      {
        src: JobView3,
        title: "Step 3",
        content: (
          <p style={{ textAlign: "justify" }}>
            You will find the link in the middle of the screen. You can share
            this link with individuals or job boards like LinkedIn/Indeed. We
            wish you all the best for your recruitment campaign!
          </p>
        ),
      },
    ],
    candidateView: [
      {
        src: JobView1,
        title: "Step 1",
        content: <p>Click 'View Jobs'.</p>,
      },
      {
        src: JobView2,
        title: "Step 2",
        content: (
          <p>Click 'View &rarr;' on the job you wish to view candidates for.</p>
        ),
      },
      {
        src: CandidateView3,
        title: "Step 3",
        content: <p>Click 'View Candidates'.</p>,
      },
      {
        src: CandidateView4,
        title: "Step 4",
        content: (
          <p style={{ textAlign: "justify" }}>
            These candidates are already proactively ranked for you considering
            their cognitive skills and the unique fit to their future manager
            and team members.
            <br />
            <br />
            Now, you can click 'View &rarr;' on the candidate you wish to see
            more information about, including their email address and resume/CV.
          </p>
        ),
      },
    ],
    candidateAdd: [
      {
        src: JobView1,
        title: "Step 1",
        content: <p>Click 'View Jobs'.</p>,
      },
      {
        src: JobView2,
        title: "Step 2",
        content: (
          <p>Click 'View &rarr;' on the job you wish to add candidates to.</p>
        ),
      },
      {
        src: CandidateView3,
        title: "Step 3",
        content: <p>Click 'View Candidates'.</p>,
      },
      {
        src: CandidateAdd4,
        title: "Step 4",
        content: (
          <p style={{ textAlign: "justify" }}>
            Click 'Add Candidates'.
            <br />
            <br />
            (Sometimes, this may be disabled for demo accounts to protect users
            from spam. When you have a live account, make a request to get it
            enabled at support@help.transhives.com and we will do so within a
            day.)
          </p>
        ),
      },
      {
        src: CandidateAdd5,
        title: "Step 5",
        content: (
          <p style={{ textAlign: "justify" }}>
            Enter the candidate email addresses (separate them with commas).
            Click 'Save Candidates'. We will send those candidates invitations
            to complete T360.
            <br />
            <br />
            <b>Caution:</b> Please double-check the email addresses to make sure
            you do not mistakenly send to unintended email addresses. They could
            report these emails and you would risk your account getting
            blacklisted by email service providers like Google/Microsoft/etc.,.
          </p>
        ),
      },
    ],
    canApply: [
      {
        src: CanApply1,
        title: "Step 1",
        content: (
          <p style={{ textAlign: "justify" }}>
            Go to the URL of the organisation you wish to apply to. This will be
            in the format of 'app.transhives.com/pages/link'.
            <br />
            (If you already have a job-specific URL, skip to Step 2.).
            <br />
            <br />
            Click 'View &rarr;' on the job you wish to apply to.
          </p>
        ),
      },
      {
        src: CanApply2,
        title: "Step 2",
        content: <p>Click 'Apply &rarr;'.</p>,
      },
      {
        src: CanApply3,
        title: "Step 3",
        content: <p>Enter the information requested and click 'Submit'.</p>,
      },
      {
        src: CanApply4,
        title: "Step 4",
        content: (
          <p style={{ textAlign: "justify" }}>
            Enter the OTP code emailed to you (for new users) or sign in
            (returning users) before we save your application. This is to
            protect your email account from spammers.
            <br />
            <br />
            Once done, CONGRATS, you have applied for this job. We wish you all
            the very best! (You may be sent an invite via email to complete T360
            - a ~10 min to understand you better.).
          </p>
        ),
      },
    ],
    t360: [
      {
        src: T3601,
        title: "Step 1",
        content: (
          <p style={{ textAlign: "justify" }}>
            Click the invitation link from the email you received.
            <br />
            <br />
            You will receive an email when someone adds you to their team (Guide
            1) or when you apply for a job (Guide 5). This link is unique to
            you. Do NOT share it.
          </p>
        ),
      },
      {
        src: T3602,
        title: "Step 2",
        content: <p>Create a password and click 'Continue'.</p>,
      },
      {
        src: T3603,
        title: "Step 3",
        content: <p>Click 'Start Now'.</p>,
      },
      {
        src: T3604,
        title: "Step 4",
        content: (
          <p style={{ textAlign: "justify" }}>
            Complete the 12 (if an existing employee) or 17 (if a candidate)
            questions. Use the buttons to navigate.
            <br />
            <br />
            No preparation is needed. Have fun reflecting! More information
            about the test and how we handle your data will be in the invitation
            you receive. We wish you all the best!
          </p>
        ),
      },
    ],
  };

  return (
    <>
      <HelpSidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <>
          <br />
          <div className="container-fluid py-4">
            <PageTitle
              title={"Help Centre"}
              helpText="TransHives is engineered to ensure a blissful user experience. Have fun!"
            />
            <Collapsible
              title="Guide 1: How to create a job?"
              carouselItems={carouselItems.jobCreate}
            />
            <Collapsible
              title="Guide 2: How to share universal job link/URL?"
              carouselItems={carouselItems.jobView}
            />
            <Collapsible
              title="Guide 3: How to view candidates?"
              carouselItems={carouselItems.candidateView}
            />
            <Collapsible
              title="Guide 4: How to add existing candidates to TransHives?"
              carouselItems={carouselItems.candidateAdd}
            />
            <br />
            <br />
            <Collapsible
              title="Guide 5: How to apply for a job?"
              carouselItems={carouselItems.canApply}
            />{" "}
            <Collapsible
              title="Guide 6: How to complete T360?"
              carouselItems={carouselItems.t360}
            />
          </div>
          <Footer />
        </>
      </main>
    </>
  );
}

export default Help;
