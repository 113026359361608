import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle, FaCheck } from "react-icons/fa";

import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../utils/axiosConfig";

import { AppContext } from "../../context/appContext";

import Alert from "../common/alert";
import Button from "../common/button";
import Input from "../common/input";
import { Link } from "react-router-dom";

function CandidateAddModal(props) {
  const { getSession, handleSignOut } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(false);
  const [spinnerText, setSpinnerText] = useState("Loading...");

  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [candidatesString, setCandidatesString] = useState("");

  async function saveClicked() {
    setIsLoading(true);

    const body = {
      candidatesString: candidatesString,
      jobID: props.jobID,
    };

    try {
      const response = await axiosInstance.post(`/candidate/add`, body, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      props.candidatesAdded(response.data);

      props.setAlertType("success");
      props.setAlertText("Candidates successfully added!");
      window.scrollTo({ top: 0, behavior: "smooth" });
      props.closeModal();
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("An invalid email found! Try again!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <Modal show={true} backdrop="static" centered>
        <Modal.Body>
          <LoadingOverlay active={isLoading} spinner text={spinnerText}>
            {alertType && (
              <Alert
                alertType={alertType}
                alertText={alertText}
                setAlertType={() => setAlertType("")}
                setAlertText={() => setAlertText("")}
              />
            )}

            <div className="row">
              <div className="col-10">
                <h6 className="mb-0">Add Candidates</h6>
              </div>
              <Button
                type={"noBgWithIcon"}
                colSize={2}
                icon={FaTimesCircle}
                text="Close"
                onClick={() => props.closeModal()}
              />
            </div>

            {props.isVerified ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <p className="mb-0">
                      If you already have their resumes, you can use this
                      section to invite them to complete T360. We will email
                      them an invite to complete T360 and link their profile.
                      Then, their applications too will be in your proactively
                      ranked list below.
                      <br />
                      <br />
                      If you don't have their resumes, don't use this. Instead,
                      share the 'Shareable Universal Link' (found in '
                      <Link to="/jobs">
                        <u>View Jobs</u>
                      </Link>
                      ', and click 'View' on the relevant job) for them to start
                      a fresh application.
                    </p>
                  </div>
                </div>
                <br />
                <div className="row">
                  <br />
                  <Input
                    disabled={false}
                    colSize={12}
                    mdSize={12}
                    rows={8}
                    type="textArea"
                    name="candidatesString"
                    placeholder="Use commas to seperate addresses (E.g.: john.doe@email.com, jane.doe@email.com)"
                    value={candidatesString}
                    onChange={(value) => setCandidatesString(value)}
                  />
                  <Button
                    formGroup={false}
                    type={"withBgWithIcon"}
                    colSize={12}
                    icon={FaCheck}
                    text="Save Candidates"
                    onClick={() => saveClicked()}
                  />
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-12">
                  <p className="mb-0">
                    If you already have their resumes, you can use this section
                    to invite them to complete T360. We will email them an
                    invite to complete T360 and link their profile. Then, their
                    applications too will be in your proactively ranked list
                    below.
                    <br />
                    <br />
                    <b>Let's Get it Activated</b>
                    <br />
                    In order to comply with policies from email service
                    providers such as Amazon SES, this feature is disabled for
                    new accounts. When you are actively using the software, make
                    a request to get it enabled at support@help.transhives.com.
                    We will do so within a day.
                  </p>
                </div>
              </div>
            )}
          </LoadingOverlay>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CandidateAddModal;
