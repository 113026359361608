import axios from "axios";

const backendURL = "https://api.transhives.com";

const axiosInstance = axios.create({
  baseURL: backendURL + "/api",
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.headers && response.headers.authorization) {
      const authHeader = response.headers.authorization;
      const newToken = authHeader.split(" ")[1];

      const localSession = localStorage.getItem("session");

      if (localSession) {
        const jsonLocalSession = JSON.parse(localSession);
        const sessionUpdate = {
          isActive: jsonLocalSession.isActive,
          authLevel: jsonLocalSession.authLevel,
          email: jsonLocalSession.email,
          token: newToken,
          resumeName: jsonLocalSession.resumeName,
          isVerified: jsonLocalSession.isVerified,
        };
        localStorage.setItem("session", JSON.stringify(sessionUpdate));
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
