import React from "react";
import { FaEdit, FaRegCheckCircle } from "react-icons/fa";

function DashboardInfo(props) {
  return (
    <div className="col-lg-6 col-md-12">
      <div className="card h-100">
        <div className="card-header pb-0 p-3">
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">
              <h6 className="mb-0">Org Information</h6>
            </div>
          </div>
        </div>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-4">Org Name: </div>
            <div className="col-8">{props.org.orgName}</div>
            <div className="col-4">Career Page URL: </div>
            <div className="col-8">
              {"app.transhives.com/pages/" + props.org.linkID}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardInfo;
