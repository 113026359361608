import React from "react";
import { Tooltip } from "react-tooltip";

import { getSmartString, isSubstring } from "../../utils/utils";

export function SmartH6(props) {
  if (props.text && props.text.trim() !== "") {
    if (isSubstring(props.text, props.maxLength)) {
      return (
        <h6
          data-tooltip-id={"tooltip-" + props._id}
          data-tooltip-content={props.text}
          className="mb-0"
        >
          {getSmartString(props.text, 0, props.maxLength)}
          <Tooltip id={"tooltip-" + props._id} style={{ zIndex: "10000" }} />
        </h6>
      );
    } else {
      return <h6 className="mb-0">{props.text}</h6>;
    }
  } else {
    return "";
  }
}

export function SmartP(props) {
  if (props.text && props.text.trim() !== "") {
    if (isSubstring(props.text, props.maxLength)) {
      return (
        <p
          data-tooltip-id={"tooltip-" + props._id}
          data-tooltip-content={props.text}
          className="mb-0"
        >
          {getSmartString(props.text, 0, props.maxLength)}
          <Tooltip id={"tooltip-" + props._id} style={{ zIndex: "10000" }} />
        </p>
      );
    } else {
      return <p className="mb-0">{props.text}</p>;
    }
  } else {
    return "";
  }
}
