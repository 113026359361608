import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import Button from "../common/button";
import Input from "../common/input";

function T360Schedule(props) {
  return (
    <Modal show={true} backdrop="static" centered>
      <Modal.Body>
        <div className="row">
          <div className="col-10">
            <h6 className="mb-0">Scheduling for T360</h6>
          </div>
          <Button
            type={"noBgWithIcon"}
            colSize={2}
            icon={FaTimesCircle}
            text="Close"
            onClick={() => props.closeModal()}
          />
        </div>
        <br />
        <div className="row">
          <Input
            disabled={false}
            colSize={8}
            mdSize={8}
            type="dateTime"
            name="t360ScheduledFor"
            value={props.t360ScheduledFor}
            onChange={(value) => props.setT360ScheduledFor(value)}
          />
          <Button
            type={"withBgWithIcon"}
            colSize={4}
            icon={FaCheckCircle}
            text="Save"
            onClick={() => props.scheduleT360()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default T360Schedule;
