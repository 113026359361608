import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../../utils/axiosConfig";
import { FaCheck } from "react-icons/fa";

import { AppContext } from "../../../context/appContext";

import Input from "../../common/input";
import Button from "../../common/button";

function TeamInputs(props) {
  const { getSession, handleSignOut } = useContext(AppContext);
  const session = getSession();

  const [teamName, setTeamName] = useState("");
  const [leadersString, setLeadersString] = useState("");
  const [membersString, setMembersString] = useState("");

  useEffect(() => {
    if (props.team) {
      setTeamName(props.team.teamName);
      if (props.team.leadersString)
        setLeadersString(
          props.team.leadersString.replace(/, /g, ",").replace(/,/g, ", ")
        );
      if (props.team.membersString)
        setMembersString(
          props.team.membersString.replace(/, /g, ",").replace(/,/g, ", ")
        );
    }
  }, []);

  return (
    <>
      <div className="row">
        <Input
          helpText={"Team Name"}
          disabled={false}
          colSize={6}
          mdSize={12}
          type="text"
          name="teamName"
          placeholder="E.g.: Awesome Team"
          value={teamName}
          onChange={(value) => setTeamName(value)}
        />
      </div>

      <div className="row">
        <Input
          helpText={"Leader Email Address(es)"}
          disabled={false}
          colSize={6}
          mdSize={12}
          rows={6}
          type="textArea"
          name="leadersString"
          placeholder="Use commas to seperate addresses (E.g.: john.doe@email.com, jane.doe@email.com)"
          value={leadersString}
          onChange={(value) => setLeadersString(value)}
        />
        <Input
          helpText={"Member Email Address(es)"}
          disabled={false}
          colSize={6}
          mdSize={12}
          rows={6}
          type="textArea"
          name="membersString"
          placeholder="Use commas to seperate addresses (E.g.: john.doe@email.com, jane.doe@email.com)"
          value={membersString}
          onChange={(value) => setMembersString(value)}
        />
      </div>

      <div className="row">
        <div className="col-9"></div>
        <Button
          type={"withBgWithIconRight"}
          colSize={3}
          icon={FaCheck}
          text={props.type === "create" ? "Create Team" : "Update Team"}
          onClick={() => onClick()}
        />
      </div>
    </>
  );

  function onClick() {
    if (props.type === "create") createTeam();
    else if (props.type === "update") updateTeam();
  }

  async function createTeam() {
    props.setIsLoading(true);

    const body = {
      team: {
        teamName: teamName,
        leadersString: leadersString,
        membersString: membersString,
      },
    };

    try {
      const response = await axiosInstance.post(`/team`, body, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      setTeamName("");
      setLeadersString("");
      setMembersString("");

      props.setAlertType("success");
      props.setAlertText("Team successfully created!");
      window.scrollTo({ top: 0, behavior: "smooth" });
      props.setIsLoading(false);
      props.teamCreated(response.data);
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        props.setAlertType("error");
        props.setAlertText("An invalid email found! Try again!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        props.setAlertType("error");
        props.setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 602) {
        props.setAlertType("error");
        props.setAlertText("Team members should be in the same domain as you!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        props.setAlertType("error");
        props.setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      props.setIsLoading(false);
    }
  }

  async function updateTeam() {
    props.setIsLoading(true);

    const body = {
      team: {
        teamName: teamName,
        leadersString: leadersString,
        membersString: membersString,
      },
    };

    try {
      const response = await axiosInstance.put(
        `/team/${props.team._id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );

      props.teamUpdated(response.data);
      props.setAlertType("success");
      props.setAlertText("Team successfully updated!");
      window.scrollTo({ top: 0, behavior: "smooth" });
      props.setIsLoading(false);
      props.closeModal();
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        props.setAlertType("error");
        props.setAlertText("An invalid email found! Try again!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        props.setAlertType("error");
        props.setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        props.setAlertType("error");
        props.setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      props.setIsLoading(false);
      props.closeModal();
    }
  }
}

export default TeamInputs;
