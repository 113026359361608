import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";

import Button from "../common/button";

function T360SubmitModal(props) {
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  return (
    <Modal show={true} backdrop="static" size="sm" centered>
      <Modal.Body>
        <div className="row">
          <div className="col-8"></div>
          <Button
            disabled={isSubmitClicked}
            type={"noBgWithIcon"}
            colSize={4}
            icon={FaTimesCircle}
            text="Close"
            onClick={() => props.closeModal()}
          />
        </div>
        <br />
        <div className="row">
          <div className="col-12">
            <center>
              <h5>Submit Answers</h5>
              <br />
              <p className="mb-0">
                You have completed T360. <br />
                Click below submit to the answers. All the best!
              </p>
              <br />
              <br />
              <Button
                disabled={isSubmitClicked}
                type={"withBgWithIconRight"}
                colSize={8}
                icon={FaCheckCircle}
                text={isSubmitClicked ? "Submitting..." : "Submit Answers"}
                onClick={() => {
                  props.t360Submit();
                  setIsSubmitClicked(true);
                }}
              />
            </center>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default T360SubmitModal;
