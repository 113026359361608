import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle, FaCheck } from "react-icons/fa";
import Slider from "rc-slider";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../utils/axiosConfig";
import "rc-slider/assets/index.css";
import "../../assets/css/custom-css.css";

import { AppContext } from "../../context/appContext";
import Auth from "../auth/auth";

import Alert from "../common/alert";
import Button from "../common/button";
import Input from "../common/input";
import { SmartP } from "../common/text";

function ApplyToJobModal(props) {
  const {
    getSession,
    isShowAuth,
    setIsShowAuth,
    handleSignOut,
    handleSignedIn,
  } = useContext(AppContext);
  const session = getSession();

  const sliderMarks = {
    1: "None",
    2: "Some",
    3: "Most",
    4: "All",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isChangeResume, setIsChangeResume] = useState(false);
  const [spinnerText, setSpinnerText] = useState("Loading...");
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [match, setMatch] = useState(3);
  const [email, setEmail] = useState(session.email);
  const [whyMe, setWhyMe] = useState("");
  const [resume, setResume] = useState(null);

  return (
    <>
      <Modal show={true} backdrop="static" centered>
        <Modal.Body>
          <LoadingOverlay active={isLoading} spinner text={spinnerText}>
            {alertType && (
              <Alert
                alertType={alertType}
                alertText={alertText}
                setAlertType={() => setAlertType("")}
                setAlertText={() => setAlertText("")}
              />
            )}

            <div className="row">
              <div className="col-10">
                <h6 className="mb-0">Applying with TransHives SeamlessApply</h6>
              </div>
              <Button
                type={"noBgWithIcon"}
                colSize={2}
                icon={FaTimesCircle}
                text="Close"
                onClick={() => props.closeModal()}
              />
            </div>
            <div className="row">
              <div className="col-12">
                <p className="mb-0">
                  <b>{props.job.jobName}</b> <br />
                  at {props.job.orgName}
                </p>
                <br />
                <p className="mb-0">
                  {/* <br />
                  This will expedite the process for you. */}
                </p>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10">
                    <Slider
                      min={1}
                      max={4}
                      marks={sliderMarks}
                      defaultValue={3}
                      step={1}
                      className="custom-slider"
                      onChange={(value) => setMatch(value)}
                    />
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div> */}
              <br />
              <Input
                helpText="Why me?"
                disabled={false}
                colSize={12}
                mdSize={12}
                rows={8}
                type="textArea"
                name="whyMe"
                placeholder="Why me? Use this opportunity to highlight why you and the job are a good match. This will help your recruiter quickly identify areas you want to highlight. All the best!"
                value={whyMe}
                onChange={(value) => setWhyMe(value)}
              />

              {session.email && session.email !== "" ? (
                <Input
                  helpText="Email Address"
                  disabled={true}
                  colSize={6}
                  mdSize={6}
                  type="text"
                  name="email"
                  value={email}
                />
              ) : (
                <Input
                  helpText="Email Address"
                  disabled={false}
                  colSize={6}
                  mdSize={6}
                  type="text"
                  name="email"
                  placeholder="john.doe@gmail.com"
                  value={email}
                  onChange={(value) => setEmail(value)}
                />
              )}

              {session.resumeName &&
              session.resumeName !== "" &&
              !isChangeResume ? (
                <div className="col-lg-6 col-md-6">
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <label class="form-label">Resume</label>{" "}
                    </div>
                    <Button
                      type={"bInline"}
                      colSize={2}
                      text="Change"
                      onClick={() => setIsChangeResume(true)}
                    />
                  </div>
                  <Input
                    disabled={true}
                    colSize={12}
                    mdSize={12}
                    type="text"
                    name="email"
                    value={session.resumeName}
                  />
                </div>
              ) : (
                <Input
                  helpText="Resume (.pdf only) | Max: 3MB"
                  colSize={6}
                  mdSize={6}
                  type="file"
                  name="resume"
                  onChange={(value) => handleResume(value)}
                />
              )}
              <Button
                formGroup={false}
                type={"withBgWithIcon"}
                colSize={12}
                icon={FaCheck}
                text="Submit"
                onClick={() => submitClicked()}
              />
            </div>
          </LoadingOverlay>
        </Modal.Body>
      </Modal>

      {isShowAuth && (
        <Auth
          isFromApply={true}
          email={email}
          onAuth={(session) => submitApplication(session)}
        />
      )}
    </>
  );

  function handleResume(resume) {
    if (resume && resume.size <= 1048576 * 5) {
      setResume(resume);
    } else {
      setAlertType("error");
      setAlertText("File size should be less than 5MB!");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  function validateInputs() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  function submitClicked() {
    if (whyMe === "") {
      setAlertType("error");
      setAlertText("All fields are required!");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (!validateInputs()) {
      setAlertType("error");
      setAlertText("Invalid email address. Try again!");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      if (session.isActive) submitApplication(session);
      else setIsShowAuth(true);
    }
  }

  async function submitApplication(session) {
    setIsLoading(true);
    setSpinnerText("Uploading...");

    const candidate = {
      jobID: props.job._id,
      match: match,
      whyMe: whyMe,
      email: email.trim(),
    };

    const formData = new FormData();

    for (const key in candidate) {
      formData.append(`candidate[${key}]`, candidate[key]);
    }
    formData.append("resume", resume);

    try {
      await axiosInstance.post(`/candidate`, formData, {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (resume) {
        const sessionWithResume = {
          isActive: session.isActive,
          authLevel: session.authLevel,
          email: session.email,
          token: session.token,
          resumeName: resume.name
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;")
            .replace(/&/g, "&amp;"),
        };
        handleSignedIn(sessionWithResume);
      }

      props.setModalToShow("showSuccess");
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 622) {
        setAlertType("error");
        setAlertText("Invalid resume/resume format!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
    setSpinnerText("Loading...");
  }
}

export default ApplyToJobModal;
