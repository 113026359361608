import React from "react";
import { FaSearch } from "react-icons/fa";

import Input from "../../common/input";
import Button from "../../common/button";

function CareerPageFilters(props) {
  return (
    <div className="col-lg-12 mt-lg-0 mt-4">
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-10">
            <div className="row">
              <Input
                disabled={false}
                colSize={6}
                mdSize={12}
                type="text"
                name="jobName"
                placeholder="Keywords (E.g.: Manager, Finance, California)"
                value={props.jobName}
                onChange={(value) => props.setJobName(value)}
              />
              <Input
                disabled={false}
                colSize={3}
                mdSize={12}
                type="select"
                name="minEdu"
                placeholder="Min. Education"
                options={props.eduOptions}
                value={props.minEdu}
                onChange={(value) => props.setMinEdu(value)}
              />
              <Input
                disabled={false}
                colSize={3}
                mdSize={12}
                type="select"
                name="minExp"
                placeholder="Min. Experience"
                options={props.expOptions}
                value={props.minExp}
                onChange={(value) => props.setMinExp(value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="row">
              <Button
                type={"withBgWithIcon"}
                colSize={6}
                icon={FaSearch}
                onClick={() => props.applyFilters()}
              />
              <Button
                type={"noBgNoIcon"}
                colSize={6}
                text="Reset"
                onClick={() => props.resetFilters()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerPageFilters;
