import React, { useState } from "react";

function Carousel(props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlide = (index) => {
    setCurrentIndex(index);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, props.carouselItems.length - 1)
    );
  };

  return (
    <div className="carousel-container position-relative">
      <div
        id="carouselExampleIndicators1"
        className="carousel slide"
        data-ride="carousel"
        data-interval="false"
      >
        <div className="carousel-inner">
          {props.carouselItems.map((item, index) => (
            <div
              key={index}
              className={`carousel-item ${index === currentIndex && "active"}`}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="about-play">
                    <br />
                    <img
                      src={item.src}
                      className="d-block w-100"
                      alt={item.alt}
                      loading="lazy"
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        border: "1px solid #191970",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 carousel-text">
                  <br />
                  <div className="about-content ml-25">
                    <div className="section-title">
                      <h2>{item.title}</h2>
                      {item.content}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {currentIndex !== 0 && (
          <button className="carousel-control-prev" onClick={handlePrev}>
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only"></span>
          </button>
        )}
        {currentIndex !== props.carouselItems.length - 1 && (
          <button className="carousel-control-next" onClick={handleNext}>
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only"></span>
          </button>
        )}
        <br />
        <br />
        <br />
        <ol className="carousel-indicators">
          {props.carouselItems.map((item, index) => (
            <li
              key={index}
              className={`carousel-indicator ${
                index === currentIndex && "active"
              }`}
              onClick={() => handleSlide(index)}
              style={{
                width: "25px",
                height: "7px",
                borderRadius: "5px",
                backgroundColor: index === currentIndex ? "#007bff" : "#ccc",
                cursor: "pointer",
                marginRight: "5px",
              }}
            ></li>
          ))}
          <br />
        </ol>
      </div>
    </div>
  );
}

export default Carousel;
