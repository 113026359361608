import React, { useEffect, useState, useContext } from "react";
import { FaCheck } from "react-icons/fa";
import axiosInstance from "../../../utils/axiosConfig";
import LoadingOverlay from "react-loading-overlay";

import { AppContext } from "../../../context/appContext";
import Auth from "../../auth/auth";

import OrgSidebar from "../../layout/orgSidebar";
import Header from "../../layout/header";
import PageTitle from "../../layout/pageTitle";
import Footer from "../../layout/footer";
import Alert from "../../common/alert";
import Input from "../../common/input";
import Button from "../../common/button";
import ShowSuccessModal from "../../modals/showSuccessModal";
import TeamInputsModal from "../teams/teamInputsModal";
import LearnMoreModal from "../../modals/learnMoreModal";

function JobCreate() {
  const {
    getSession,
    isShowAuth,
    setCurrentPageID,
    setIsShowAuth,
    handleSignOut,
  } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [isShowModal, setIsShowModal] = useState(false);
  const [modalToShow, setModalToShow] = useState("");
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  const [eduOptions, setEduOptions] = useState([]);
  const [expOptions, setExpOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const [jobName, setJobName] = useState("");
  const [location, setLocation] = useState("");
  const [minEdu, setMinEdu] = useState("");
  const [minExp, setMinExp] = useState("");
  const [team, setTeam] = useState("");
  const [desc, setDesc] = useState("");
  const [t360For, setT360For] = useState("all");

  useEffect(() => {
    setCurrentPageID("jobCreate");
    document.title = "Create a Job | TransHives";
    if (session.authLevel >= 3) fetchData(session);
    else setIsShowAuth(true);
  }, []);

  async function fetchData(session) {
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/util/createJob`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      setEduOptions(response.data.eduOptions);
      setExpOptions(response.data.expOptions);
      setTeamOptions(response.data.teamOptions);
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <OrgSidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        {alertType && (
          <Alert
            alertType={alertType}
            alertText={alertText}
            setAlertType={() => setAlertType("")}
            setAlertText={() => setAlertText("")}
          />
        )}

        <Header />
        {session.isActive && session.authLevel >= 3 && (
          <>
            <LoadingOverlay active={isLoading} spinner text="Loading...">
              <div className="container-fluid py-4">
                <PageTitle
                  title={"Create a Job"}
                  helpText="Create a new job. You can also attach a team to this job right from this page."
                />
                <div className="row">
                  <div className="col-lg-12 mt-lg-0 mt-4">
                    <div className="card-body pt-0">
                      <div className="row">
                        <Input
                          helpText={"Job Name"}
                          disabled={false}
                          colSize={5}
                          mdSize={12}
                          type="text"
                          name="jobName"
                          placeholder="E.g.: Manager - Finance"
                          value={jobName}
                          onChange={(value) => setJobName(value)}
                        />
                        <Input
                          helpText={"Location"}
                          disabled={false}
                          colSize={5}
                          mdSize={12}
                          type="text"
                          name="location"
                          placeholder="E.g.: San Jose, California, United States"
                          value={location}
                          onChange={(value) => setLocation(value)}
                        />
                      </div>

                      <div className="row">
                        <Input
                          helpText={"Minimum Education"}
                          disabled={false}
                          colSize={5}
                          mdSize={12}
                          type="select"
                          name="minEdu"
                          options={eduOptions}
                          value={minEdu}
                          onChange={(value) => setMinEdu(value)}
                        />
                        <Input
                          helpText={"Minimum Experience"}
                          disabled={false}
                          colSize={5}
                          mdSize={12}
                          type="select"
                          name="minExp"
                          options={expOptions}
                          value={minExp}
                          onChange={(value) => setMinExp(value)}
                        />
                      </div>

                      <div className="row">
                        <Input
                          helpText="Team"
                          disabled={false}
                          colSize={8}
                          mdSize={12}
                          type="select"
                          name="team"
                          options={teamOptions}
                          value={team}
                          onChange={(value) => setTeam(value)}
                        />
                        <Button
                          marginTop={"43px"}
                          type={"noBgNoIcon"}
                          colSize={1}
                          text="Why?"
                          onClick={() => setShowLearnMoreModal(true)}
                        />
                        <Button
                          marginTop={"43px"}
                          type={"noBgNoIcon"}
                          colSize={1}
                          text="Add Team"
                          onClick={() => {
                            setIsShowModal(true);
                            setModalToShow("teamInputs");
                          }}
                        />
                      </div>

                      <div className="row">
                        <Input
                          helpText={"Other Info"}
                          disabled={false}
                          colSize={10}
                          mdSize={12}
                          rows={6}
                          type="textArea"
                          name="desc"
                          placeholder="Share any job details that will help the candidate better understand the requirements."
                          value={desc}
                          onChange={(value) => setDesc(value)}
                        />
                      </div>

                      <div className="row">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-2">
                              <label
                                class="form-label"
                                style={{ marginTop: "10px" }}
                              >
                                T360 For
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                type="radio"
                                style={{
                                  marginRight: "5px",
                                  marginTop: "10px",
                                  width: "15px",
                                  height: "15px",
                                }}
                                checked={t360For === "all"}
                                onChange={() => setT360For("all")}
                              />{" "}
                              All (Recommended)
                            </div>
                            {/* <div className="col-5">
                              <input
                                type="radio"
                                style={{
                                  marginRight: "5px",
                                  marginTop: "10px",
                                  width: "15px",
                                  height: "15px",
                                }}
                                checked={t360For === "inReview"}
                                onChange={() => setT360For("inReview")}
                              />
                              "In Review" Only
                            </div> */}
                          </div>
                        </div>
                        <Button
                          type={"withBgWithIconRight"}
                          colSize={2}
                          icon={FaCheck}
                          text="Create Job"
                          onClick={() => createJob()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LoadingOverlay>
            <Footer />
          </>
        )}
      </main>

      {isShowModal && modalToShow === "showSuccess" && (
        <ShowSuccessModal
          header="Created"
          text="You successfully created the job. Click to close and go to view your job manager."
          type="withRedirect"
          redirectTo="/jobs"
          navVars={{ isFromJobCreate: true }}
        />
      )}
      {isShowModal && modalToShow === "teamInputs" && (
        <TeamInputsModal
          type="create"
          setAlertType={(value) => setAlertType(value)}
          setAlertText={(value) => setAlertText(value)}
          teamCreated={(value) => teamCreated(value)}
          closeModal={() => setIsShowModal(false)}
        />
      )}
      {isShowAuth && (
        <Auth isFromApply={false} onAuth={(session) => fetchData(session)} />
      )}
      {showLearnMoreModal && (
        <LearnMoreModal
          type="jobCreate"
          showLearnMoreModal={showLearnMoreModal}
          setShowLearnMoreModal={() => setShowLearnMoreModal(false)}
        />
      )}
    </>
  );

  async function teamCreated(value) {
    let array = [...teamOptions];
    array.unshift(value);
    setTeamOptions(array);

    setTeam(value);

    setIsShowModal(false);
    setModalToShow("");
  }

  async function createJob() {
    setIsLoading(true);

    const body = {
      job: {
        jobName: jobName,
        location: location,
        minEdu: minEdu,
        minExp: minExp,
        team: team,
        desc: desc,
        t360For: t360For,
      },
    };

    try {
      await axiosInstance.post(`/job`, body, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      setIsShowModal(true);
      setModalToShow("showSuccess");
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 601) {
        setAlertType("error");
        setAlertText(
          "Insufficient job credits. Contact Support to purchase more!"
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }
}

export default JobCreate;
