import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import axiosInstance from "../../../utils/axiosConfig";
import LoadingOverlay from "react-loading-overlay";

import { AppContext } from "../../../context/appContext";
import Auth from "../../auth/auth";

import OrgSidebar from "../../layout/orgSidebar";
import Footer from "../../layout/footer";
import Alert from "../../common/alert";
import Header from "../../layout/header";

import DashboardInfo from "./dashboardInfo";
import PageTitle from "../../layout/pageTitle";
import JobCreditInfo from "./jobCreditInfo";
import DashboardHelp from "./dashboardHelp";

function Dashboard() {
  const navigate = useNavigate();
  const {
    getSession,
    isShowAuth,
    setIsShowAuth,
    setCurrentPageID,
    handleSignOut,
  } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [isShowModal, setIsShowModal] = useState(false);
  const [modalToShow, setModalToShow] = useState("viewJob");

  const [org, setOrg] = useState({});

  useEffect(() => {
    setCurrentPageID("dashboard");
    document.title = "Dashboard | TransHives";

    if (session.authLevel >= 3) fetchData(session);
    else setIsShowAuth(true);
  }, []);

  async function fetchData(session) {
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/org`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      if (!response.data.org.orgName)
        navigate("/explore/full", { state: { stepToShow: "step1" } });
      else if (!response.data.org.paid)
        navigate("/explore/full", { state: { stepToShow: "step2" } });
      else setOrg(response.data.org);
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <OrgSidebar />

      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        {alertType && (
          <Alert
            alertType={alertType}
            alertText={alertText}
            setAlertType={() => setAlertType("")}
            setAlertText={() => setAlertText("")}
          />
        )}

        <Header />
        {session.isActive && session.authLevel >= 3 && (
          <>
            <LoadingOverlay active={isLoading} spinner text="Loading...">
              <div className="container-fluid py-4">
                <PageTitle
                  title={"Dashboard"}
                  helpText="Welcome to your TransHives Dashboard. Use the left pane to navigate."
                />
                <div className="row">
                  <DashboardInfo org={org} />
                  <DashboardHelp />
                  {/* <JobCreditInfo org={org} /> */}
                </div>
              </div>
            </LoadingOverlay>

            <Footer />
          </>
        )}
      </main>

      {isShowAuth && (
        <Auth isFromApply={false} onAuth={(session) => fetchData(session)} />
      )}
    </>
  );
}

export default Dashboard;
