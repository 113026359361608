import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "../../../context/appContext";
import { FaChevronCircleRight } from "react-icons/fa";
import axiosInstance from "../../../utils/axiosConfig";

import Input from "../../common/input";
import Button from "../../common/button";

function CreatePassword(props) {
  const navigate = useNavigate();

  const { handleSignedIn } = useContext(AppContext);

  const email = props.email;
  const key = props.inviteKey;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  async function createPassword() {
    const regexPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (password === "" || !regexPattern.test(password)) {
      props.setAlertType("info");
      props.setAlertText(
        "A password needs at least eight characters, one uppercase letter, one lowercase letter, one number and one special character."
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      if (password === confirmPassword) {
        props.setIsLoading(true);

        const body = {
          email: email.trim(),
          key: key,
          password: password,
          confirmPassword: confirmPassword,
        };

        try {
          const response = await axiosInstance.post(`/auth/user/create`, body);

          const authHeader = response.headers.authorization;
          const token = authHeader.split(" ")[1];

          const session = {
            isActive: true,
            authLevel: response.data.authLevel,
            email: response.data.email,
            token: token,
            resumeName: response.data.resumeName,
            isVerified: response.data.isVerified,
          };

          handleSignedIn(session);
          navigate("/profile");
        } catch (error) {
          if (error.response && error.response.status === 429) {
            props.setAlertType("error");
            props.setAlertText("Too many requests from your network!");
            window.scrollTo({ top: 0, behavior: "smooth" });
          } else {
            props.setAlertType("error");
            props.setAlertText(error.message);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }
        props.setIsLoading(false);
      } else {
        props.setAlertType("error");
        props.setAlertText("Passwords don't match. Please try again!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }

  return (
    <div className="row">
      <p className="mb-0">
        Welcome to T360 by TransHives!
        <br />
        Let's create a password and continue.
      </p>
      <br />
      <br />
      <br /> <br />
      <Input
        disabled={true}
        colSize={12}
        mdSize={12}
        type="text"
        name="email"
        placeholder="Email Address"
        value={email}
      />
      <Input
        disabled={false}
        colSize={12}
        mdSize={12}
        type="password"
        name="password"
        placeholder="Password"
        value={password}
        onChange={(value) => setPassword(value)}
      />
      <Input
        disabled={false}
        colSize={12}
        mdSize={12}
        type="password"
        name="confirmPassword"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(value) => setConfirmPassword(value)}
      />
      <Button
        type={"withBgWithIconRight"}
        colSize={12}
        icon={FaChevronCircleRight}
        text="Continue"
        onClick={() => createPassword()}
      />
    </div>
  );
}

export default CreatePassword;
