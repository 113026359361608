import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../../utils/axiosConfig";
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router";

import { AppContext } from "../../../context/appContext";
import Auth from "../../auth/auth";

import OrgSidebar from "../../layout/orgSidebar";
import Header from "../../layout/header";
import PageTitle from "../../layout/pageTitle";
import Footer from "../../layout/footer";
import Alert from "../../common/alert";
import LoadMore from "../../common/loadMore";

import JobCard from "./jobCard";
import JobInfoModal from "./jobInfoModal";
import JobFilters from "./jobFilters";

function Jobs() {
  const {
    getSession,
    isShowAuth,
    setCurrentPageID,
    setIsShowAuth,
    handleSignOut,
  } = useContext(AppContext);
  const session = getSession();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [isAllRecords, setIsAllRecords] = useState(false);
  const [page, setPage] = useState(1);

  const [eduOptions, setEduOptions] = useState([]);
  const [expOptions, setExpOptions] = useState([]);

  const [isShowModal, setIsShowModal] = useState(false);

  const [indexOfJob, setIndexOfJob] = useState("");
  const [job, setJob] = useState({});
  const [jobs, setJobs] = useState([]);

  const [linkID, setLinkID] = useState("");
  const [jobName, setJobName] = useState("");
  const [minEdu, setMinEdu] = useState("");
  const [minExp, setMinExp] = useState("");

  useEffect(() => {
    setCurrentPageID("viewJobs");
    document.title = "View Jobs | TransHives";
    if (session.authLevel >= 3)
      fetchData(session, jobName, minEdu, minExp, page);
    else setIsShowAuth(true);
  }, []);

  async function fetchData(session, jobName, minEdu, minExp, page) {
    setIsLoading(true);
    setIsAllRecords(false);

    const queryParams = {
      jobName: jobName,
      minEdu: minEdu,
      minExp: minExp,
      page: page,
      showCompleted: true,
    };

    try {
      const response = await axiosInstance.get(`/job/org/org`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
        params: queryParams,
      });

      updateIsAllRecords(response.data.jobsFound, jobs);

      setEduOptions(response.data.eduOptions);
      setExpOptions(response.data.expOptions);
      setLinkID(response.data.linkID);

      setJobs(response.data.jobsFound);

      if (
        location.state &&
        location.state.navVars &&
        location.state.navVars.isFromJobCreate
      ) {
        viewJob(response.data.jobsFound[0], 0);
      }
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  function updateIsAllRecords(incomingArray, existingArray) {
    if (incomingArray.length % 9 !== 0) {
      setIsAllRecords(true);
    } else {
      const sortedArr1 = incomingArray.slice().sort();
      const sortedArr2 = existingArray.slice().sort();
      if (JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)) {
        setIsAllRecords(true);
      }
    }
  }

  function applyFilters() {
    setJobs([]);
    fetchData(session, jobName, minEdu, minExp, 1);
    setPage(1);
  }

  function resetFilters() {
    setJobs([]);
    setJobName("");
    setMinEdu(null);
    setMinExp(null);
    fetchData(session, null, null, null, 1);
    setPage(1);
  }

  function loadMore() {
    fetchData(session, jobName, minEdu, minExp, page + 1);
    setPage(page + 1);
  }

  function viewJob(job, index) {
    setJob(job);
    setIndexOfJob(index);
    setIsShowModal(true);
  }

  return (
    <>
      <OrgSidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        {alertType && (
          <Alert
            alertType={alertType}
            alertText={alertText}
            setAlertType={() => setAlertType(null)}
            setAlertText={() => setAlertText(null)}
          />
        )}

        <Header />
        {session.authLevel >= 3 && (
          <>
            <LoadingOverlay active={isLoading} spinner text="Loading...">
              <div className="container-fluid py-4">
                <PageTitle
                  title={"View Jobs/Candidates"}
                  helpText="View your jobs here. Click on a job to start managing the candidates."
                />
                <JobFilters
                  eduOptions={eduOptions}
                  expOptions={expOptions}
                  jobName={jobName}
                  minEdu={minEdu}
                  minExp={minExp}
                  setJobName={(value) => setJobName(value)}
                  setMinEdu={(value) => setMinEdu(value)}
                  setMinExp={(value) => setMinExp(value)}
                  applyFilters={() => applyFilters()}
                  resetFilters={() => resetFilters()}
                />

                <div className="row">
                  {jobs.map((job, index) => (
                    <JobCard
                      key={job._id}
                      job={job}
                      viewJob={() => viewJob(job, index)}
                    />
                  ))}
                </div>
                <LoadMore
                  isAllRecords={isAllRecords}
                  loadMore={() => loadMore()}
                />
              </div>
            </LoadingOverlay>
            <Footer />
          </>
        )}
      </main>

      {isShowModal && (
        <JobInfoModal
          type="viewJobs"
          linkID={linkID}
          job={job}
          closeModal={() => setIsShowModal(false)}
          markAsComplete={() => markAsComplete(job, indexOfJob)}
        />
      )}
      {isShowAuth && (
        <Auth
          isFromApply={false}
          onAuth={(session) =>
            fetchData(session, jobName, minEdu, minExp, page)
          }
        />
      )}
    </>
  );

  async function markAsComplete(job, indexOfJob) {
    setIsLoading(true);

    try {
      await axiosInstance.put(`/job/org/markComplete/${job._id}`, null, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      let array = [...jobs];
      array[indexOfJob].status = "completed";
      setJobs(array);

      setAlertType("success");
      setAlertText("Job successfully marked complete!");
      setIsShowModal(false);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setIsShowModal(false);
      setIsLoading(false);
    }
  }
}

export default Jobs;
