import React from "react";
import { FaSearch } from "react-icons/fa";

import Input from "../../common/input";
import Button from "../../common/button";

function CandidateFilters(props) {
  const stageOptions = [
    { value: "new", label: "New" },
    { value: "inReview", label: "In Review" },
    { value: "reserve", label: "Reserve" },
  ];

  const orderByOptions = [
    { value: "tScore", label: "TScore" },
    { value: "appliedOn", label: "Applied On" },
  ];

  return (
    <div className="col-lg-12 mt-lg-0 mt-4">
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-10">
            <div className="row">
              <Input
                disabled={false}
                colSize={6}
                mdSize={12}
                type="text"
                name="candidateName"
                placeholder="E.g.: John Doe"
                value={props.candidateName}
                onChange={(value) => props.setCandidateName(value)}
              />
              <Input
                disabled={false}
                colSize={3}
                mdSize={12}
                type="select"
                name="stage"
                placeholder="Stage"
                options={stageOptions}
                value={props.stage}
                onChange={(value) => props.setStage(value)}
              />
              <Input
                disabled={false}
                colSize={3}
                mdSize={12}
                type="select"
                name="orderBy"
                placeholder="Order By"
                options={orderByOptions}
                value={props.orderBy}
                onChange={(value) => props.setOrderBy(value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="row">
              <Button
                type={"withBgWithIcon"}
                colSize={6}
                icon={FaSearch}
                onClick={() => props.applyFilters()}
              />
              <Button
                type={"noBgNoIcon"}
                colSize={6}
                text="Reset"
                onClick={() => props.resetFilters()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidateFilters;
