import React, { useState, useEffect } from "react";

const CountdownTimer = (timeInSeconds) => {
  const initialTime = timeInSeconds;
  const [timeLeft, setTimeLeft] = useState(initialTime);

  const resetTimer = () => {
    setTimeLeft(initialTime);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;

  return { timeLeft, formattedTime, resetTimer };
};

export default CountdownTimer;
