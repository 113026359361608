import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../../utils/axiosConfig";
import LoadingOverlay from "react-loading-overlay";

import { AppContext } from "../../../context/appContext";
import Auth from "../../auth/auth";

import OrgSidebar from "../../layout/orgSidebar";
import Header from "../../layout/header";
import PageTitle from "../../layout/pageTitle";
import Footer from "../../layout/footer";
import Alert from "../../common/alert";
import LoadMore from "../../common/loadMore";

import TeamCard from "./teamCard";
import TeamFilters from "./teamFilters";
import TeamInfoModal from "./teamInfoModal";

function Teams() {
  const {
    getSession,
    isShowAuth,
    setCurrentPageID,
    setIsShowAuth,
    handleSignOut,
  } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [isAllRecords, setIsAllRecords] = useState(false);
  const [page, setPage] = useState(1);

  const [isShowModal, setIsShowModal] = useState(false);

  const [indexOfTeam, setIndexOfTeam] = useState("");
  const [team, setTeam] = useState({});
  const [teams, setTeams] = useState([]);

  const [teamName, setTeamName] = useState("");

  useEffect(() => {
    setCurrentPageID("viewTeams");
    document.title = "Teams | TransHives";
    if (session.authLevel >= 3) fetchData(session, teamName, page);
    else setIsShowAuth(true);
  }, []);

  async function fetchData(session, teamName, page) {
    setIsLoading(true);
    setIsAllRecords(false);

    const queryParams = {
      teamName: teamName,
      page: page,
    };

    try {
      const response = await axiosInstance.get(`/team`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
        params: queryParams,
      });

      updateIsAllRecords(response.data, teams);

      setTeams(response.data);
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  function updateIsAllRecords(incomingArray, existingArray) {
    if (incomingArray.length % 9 !== 0) {
      setIsAllRecords(true);
    } else {
      const sortedArr1 = incomingArray.slice().sort();
      const sortedArr2 = existingArray.slice().sort();
      if (JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)) {
        setIsAllRecords(true);
      }
    }
  }

  function applyFilters() {
    setTeams([]);
    fetchData(session, teamName, 1);
    setPage(1);
  }

  function resetFilters() {
    setTeams([]);
    setTeamName("");
    fetchData(session, null, 1);
    setPage(1);
  }

  function loadMore() {
    fetchData(session, teamName, page + 1);
    setPage(page + 1);
  }

  return (
    <>
      <OrgSidebar />

      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        {alertType && (
          <Alert
            alertType={alertType}
            alertText={alertText}
            setAlertType={() => setAlertType(null)}
            setAlertText={() => setAlertText(null)}
          />
        )}

        <Header />
        {session.authLevel >= 3 && (
          <>
            <LoadingOverlay active={isLoading} spinner text="Loading...">
              <div className="container-fluid py-4">
                <PageTitle title={"View Teams"} />
                <TeamFilters
                  teamName={teamName}
                  setTeamName={(value) => setTeamName(value)}
                  applyFilters={() => applyFilters()}
                  resetFilters={() => resetFilters()}
                />

                <div className="row">
                  {teams.map((team, index) => (
                    <TeamCard
                      key={team._id}
                      team={team}
                      viewTeam={() => getTeam(team, index)}
                    />
                  ))}
                </div>
                <LoadMore
                  isAllRecords={isAllRecords}
                  loadMore={() => loadMore()}
                />
              </div>
            </LoadingOverlay>
            <Footer />
          </>
        )}
      </main>

      {isShowModal && (
        <TeamInfoModal
          team={team}
          teamUpdated={(value) => teamUpdated(value)}
          setAlertType={(value) => setAlertType(value)}
          setAlertText={(value) => setAlertText(value)}
          closeModal={() => setIsShowModal(false)}
        />
      )}

      {isShowAuth && (
        <Auth
          isFromApply={false}
          onAuth={(session) => fetchData(session, teamName, page)}
        />
      )}
    </>
  );

  async function getTeam(team, index) {
    setIndexOfTeam(index);
    setIsLoading(true);
    setIsAllRecords(false);

    try {
      const response = await axiosInstance.get(`/team/${team._id}`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      setTeam(response.data);
      setIsShowModal(true);
    } catch (error) {
      if (error.response && error.response.status === 600) handleSignOut();
      else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  function teamUpdated(payload) {
    setTeam(payload.team);

    let array = [...teams];
    array[indexOfTeam] = payload.forTeams;
    setTeams(array);
  }
}

export default Teams;
