import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../context/appContext";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../utils/axiosConfig";
import {
  FaArrowCircleLeft,
  FaArrowAltCircleRight,
  FaSignInAlt,
  FaCheckCircle,
} from "react-icons/fa";

import Alert from "../common/alert";
import Input from "../common/input";
import Button from "../common/button";

import logo from "../../assets/img/transhives-logo.png";
import { Link } from "react-router-dom";
import CountDownTimer from "../common/cdTimer";

function Auth(props) {
  const cdTimer = CountDownTimer(120);
  const navigate = useNavigate();

  const { getSession, handleSignedIn, setIsShowAuth, currentPageID } =
    useContext(AppContext);
  const session = getSession();

  const isFromApply = props.isFromApply;
  const [authToShow, setAuthToShow] = useState("signIn1");
  const [helpTitle, setHelpTitle] = useState("Sign In");
  const [helpText, setHelpText] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState("");
  const [emailOTP, setEmailOTP] = useState("");

  useEffect(() => {
    if (isFromApply) getHasAccount();
    if (session.isActive && session.authLevel <= 1)
      navigate("/explore/full", { state: { stepToShow: "step2" } });
  }, []);

  async function getHasAccount() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
    };

    try {
      const response = await axiosInstance.post("/auth/user/hasAccount", body);

      if (isFromApply && email) {
        if (response.data.hasAccount) setAuthToShow("signIn2");
        else setAuthToShow("userGuest");
      } else {
        if (response.data.hasAccount) setAuthToShow("signIn2");
        else setHelpText("You do not have an account. Sign Up");
      }

      setIsLoading(false);
    } catch (error) {
      setAlertType("error");

      if (error.response && error.response.status === 422)
        setAlertText("Invalid email address!");
      else if (error.response && error.response.status === 401)
        setAlertText("Invalid email address/password!");
      else if (error.response && error.response.status === 429)
        setAlertText("Too many requests from your network!");
      else setAlertText(error.message);

      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  async function signIn() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
      password: password,
    };

    try {
      const response = await axiosInstance.post(`/auth/user/signIn`, body);

      const authHeader = response.headers.authorization;
      const token = authHeader.split(" ")[1];

      const session = {
        isActive: true,
        authLevel: response.data.authLevel,
        email: response.data.email,
        token: token,
        resumeName: response.data.resumeName,
        isVerified: response.data.isVerified,
      };

      handleSignedIn(session);
      props.onAuth(session);
      setIsShowAuth(false);

      setIsLoading(false);
    } catch (error) {
      setAlertType("error");

      if (error.response && error.response.status === 422)
        setAlertText("Invalid email address!");
      else if (error.response && error.response.status === 401)
        setAlertText("Invalid email address/password!");
      else if (error.response && error.response.status === 429)
        setAlertText("Too many requests from your network!");
      else setAlertText(error.message);

      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  async function confirmEmailOTP() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
      emailOTP: emailOTP.trim(),
    };

    try {
      const response = await axiosInstance.post(
        `/auth/user/checkEmailOTP`,
        body
      );

      const authHeader = response.headers.authorization;
      const token = authHeader.split(" ")[1];

      const session = {
        isActive: true,
        authLevel: response.data.authLevel,
        email: response.data.email,
        token: token,
        isVerified: response.data.isVerified,
      };

      handleSignedIn(session);
      props.onAuth(session);
      setIsShowAuth(false);
    } catch (error) {
      setAlertType("error");

      if (error.response && error.response.status === 401)
        setAlertText("Invalid OTP.");
      else if (error.response && error.response.status === 429)
        setAlertText("Too many requests from your network!");
      else setAlertText(error.message);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsLoading(false);
  }

  async function resendEmail() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
    };

    try {
      await axiosInstance.post(`/auth/user/resendEmail`, body);
      cdTimer.resetTimer();
      setAlertType("success");
      setAlertText("New OTP successfully sent!");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      setAlertType("error");

      if (error.response && error.response.status === 422)
        setAlertText("Invalid email address!");
      else if (error.response && error.response.status === 401)
        setAlertText("Invalid email address/password!");
      else if (error.response && error.response.status === 429)
        setAlertText("Too many requests from your network!");
      else setAlertText(error.message);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setIsLoading(false);
  }

  async function resetPwd() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
    };

    try {
      await axiosInstance.post(`/auth/resetPwd`, body);
      setAuthToShow("");
      setAuthToShow("resetPwdSent");
    } catch (error) {
      setAlertType("error");

      if (error.response && error.response.status === 422)
        setAlertText("Invalid email address!");
      else if (error.response && error.response.status === 401)
        setAlertText("Invalid email address/password!");
      else if (error.response && error.response.status === 429)
        setAlertText("Too many requests from your network!");
      else setAlertText(error.message);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setIsLoading(false);
  }

  function backClicked() {
    if (isFromApply) setIsShowAuth(false);
    else if (authToShow === "signIn1") setIsShowAuth(false);
    else if (
      authToShow === "signIn2" ||
      authToShow === "signUp" ||
      authToShow === "inviteSent"
    ) {
      setAuthToShow("signIn1");
      setHelpTitle("Sign In");
    } else if (authToShow === "resetPwd" || authToShow === "resetPwdSent") {
      setHelpTitle("Sign In");
      setAuthToShow("signIn1");
    }
  }

  function renderContent() {
    if (session.isActive && session.authLevel <= 2) {
      return (
        <>
          <p className="mb-0">
            Please complete your account to access this page. An email has been
            sent to you with instructions.
          </p>
          <br />
        </>
      );
    } else if (authToShow === "signIn1") {
      return (
        <>
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="text"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(value) => setEmail(value)}
          />
          <Button
            type={"withBgWithIcon"}
            colSize={12}
            icon={FaArrowAltCircleRight}
            text="Next"
            onClick={() => setAuthToShow("signIn2")}
          />

          {currentPageID === "dashboard" && (
            <Link to="/explore">
              <Button
                type={"noBgNoIcon"}
                colSize={12}
                text="Sign Up"
                onClick={() => console.log("")}
              />
            </Link>
          )}
          {/* {currentPageID === "profile" && (
            <Button
              type={"noBgNoIcon"}
              colSize={12}
              text="Sign Up"
              onClick={() => {
                setHelpTitle("Request Invite");
                setAuthToShow("signUp");
              }}
            />
          )} */}
        </>
      );
    } else if (authToShow === "signIn2") {
      return (
        <>
          <Input
            disabled={true}
            colSize={12}
            mdSize={12}
            type="text"
            name="email"
            placeholder="Email Address"
            value={email}
          />
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(value) => setPassword(value)}
          />
          <Button
            type={"withBgWithIcon"}
            colSize={12}
            icon={FaSignInAlt}
            text="Sign In"
            onClick={() => signIn()}
          />
          <Button
            type={"noBgNoIcon"}
            colSize={12}
            text="Forgot Password?"
            onClick={() => {
              setHelpTitle("Reset Password");
              setAuthToShow("resetPwd");
            }}
          />
        </>
      );
    } else if (authToShow === "userGuest") {
      return (
        <>
          <Input
            disabled={true}
            colSize={12}
            mdSize={12}
            type="text"
            name="email"
            placeholder="Email Address"
            value={email}
          />
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="text"
            name="emailOTP"
            placeholder="OTP Code"
            value={emailOTP}
            onChange={(value) => setEmailOTP(value)}
          />
          <Button
            type={"withBgWithIcon"}
            colSize={12}
            icon={FaCheckCircle}
            text="Confirm"
            onClick={() => confirmEmailOTP()}
          />
          <Button
            disabled={cdTimer.timeLeft > 0 ? true : false}
            type={"noBgNoIcon"}
            colSize={12}
            text={"Resend Email  (Enabled in: " + cdTimer.formattedTime + ")"}
            onClick={() => resendEmail()}
          />
        </>
      );
    } else if (authToShow === "signUp") {
      return (
        <>
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="text"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(value) => setEmail(value)}
          />
          <Button
            type={"withBgWithIcon"}
            colSize={12}
            icon={FaArrowAltCircleRight}
            text="Send Invite"
            onClick={() => setAuthToShow("inviteSent")}
          />
        </>
      );
    } else if (authToShow === "resetPwd") {
      return (
        <>
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="text"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(value) => setEmail(value)}
          />
          <Button
            type={"withBgNoIcon"}
            colSize={12}
            text="Send Reset Email"
            onClick={() => resetPwd()}
          />
        </>
      );
    } else if (authToShow === "resetPwdSent") {
      return (
        <>
          <p className="mb-0">
            If the email entered is valid, an email has been sent to you
            <br /> with the instructions to reset your password. Cheers!
          </p>
          <br />
        </>
      );
    } else if (authToShow === "inviteSent") {
      return (
        <>
          <p className="mb-0">
            Thank you for the request. An email has been sent to you with
            instructions to set up your account.
          </p>
          <br />
        </>
      );
    }
  }

  return (
    <Modal show={true} backdrop="static" size="sm" centered>
      <Modal.Body>
        <LoadingOverlay active={isLoading} spinner text="Loading...">
          {alertType && (
            <Alert
              alertType={alertType}
              alertText={alertText}
              setAlertType={() => setAlertType(null)}
              setAlertText={() => setAlertText(null)}
            />
          )}

          <div className="row">
            <Button
              type={"noBgWithIcon"}
              colSize={4}
              icon={FaArrowCircleLeft}
              text="Back"
              onClick={() => backClicked()}
            />

            <center>
              <div className="card-header text-center pt-4">
                <center>
                  <img src={logo} height="50px" />
                  <br /> <h5>{helpTitle}</h5>
                  <br />
                  {renderContent()}
                </center>
              </div>
            </center>
          </div>
        </LoadingOverlay>
      </Modal.Body>
    </Modal>
  );
}

export default Auth;
