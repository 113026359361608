import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "../../../context/appContext";
import { FaChevronCircleRight } from "react-icons/fa";
import axiosInstance from "../../../utils/axiosConfig";

import Input from "../../common/input";
import Button from "../../common/button";
import Auth from "../../auth/auth";
import ShowSuccessModal from "../../modals/showSuccessModal";

function TeamInvite(props) {
  const { isShowAuth, setIsShowAuth } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [isShowModal, setIsShowModal] = useState(false);

  const email = props.email;
  const key = props.inviteKey;

  async function linkAccounts(session) {
    setIsLoading(true);

    const body = {
      email: email.trim(),
      key: key,
    };

    try {
      await axiosInstance.put(`/user/linkAccounts`, body, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      setIsShowModal(true);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="row">
        <p className="mb-0">
          Welcome to T360 by TransHives!
          <br />
          <br />
          You have been invited to complete TransHives T360. No preparation is
          needed. Not timed. Be yourself. Have fun!
        </p>
        <br />
        <br /> <br />
        <br />
        <br />
        <br />
        <Input
          disabled={true}
          colSize={12}
          mdSize={12}
          type="text"
          name="email"
          placeholder="Email Address"
          value={email}
        />
        <Button
          type={"withBgWithIconRight"}
          colSize={6}
          icon={FaChevronCircleRight}
          text="Create New"
          onClick={() => props.setOptionToDisplay("create")}
        />
        {/* <Button
          type={"aArrow"}
          colSize={6}
          text={"Sign In"}
          marginTop="5px"
          onClick={() => setIsShowAuth(true)}
        /> */}
      </div>

      {isShowModal && (
        <ShowSuccessModal
          header="Created"
          text="Congrats! You have successfully linked your accounts. Click to view your profile."
          type="withRedirect"
          redirectTo="/profile"
        />
      )}

      {isShowAuth && (
        <Auth isFromApply={false} onAuth={(session) => linkAccounts(session)} />
      )}
    </>
  );
}

export default TeamInvite;
