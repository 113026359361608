import React from "react";

import bgImage from "../../../assets/img/curved-images/curved9.jpg";
import clientLogo from "../../../assets/img/apple-logo.jpg";

function CareerPageHeader(props) {
  return (
    <div className="container-fluid">
      <div
        className="page-header min-height-200 border-radius-xl mt-4"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPositionY: "50%",
        }}
      >
        <span className="mask bg-gradient-primary opacity-6"></span>
      </div>
      <div className="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden">
        <div className="row gx-4">
          <div className="col-auto">
            <div className="avatar avatar-xl position-relative">
              <img
                src="https://static.vecteezy.com/system/resources/previews/008/326/021/original/building-logo-icon-design-template-free-vector.jpg"
                className="w-100 border-radius-lg shadow-sm"
              />
            </div>
          </div>
          <div className="col-auto my-auto">
            <div className="h-100">
              <h5 className="mb-1">{props.org.orgName}</h5>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div className="nav-wrapper position-relative end-0">
              {/* <ul
                className="nav nav-pills nav-fill p-1 bg-transparent"
                role="tablist"
              >
                <li className="nav-item">
                  <button
                    className="btn bg-gradient-primary mt-3 w-80"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="true"
                  >
                    <FaAmazon />
                    <span className="ms-1">App</span>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="btn bg-gradient-primary mt-3 w-80"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="true"
                  >
                    <FaMicrosoft />
                    <span className="ms-1">App</span>
                  </button>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerPageHeader;
