import React from "react";
import Input from "../../common/input";

function T360BAttempt(props) {
  return (
    <>
      {props.question.options.map((option) => {
        return (
          <div className="form-check" style={{ marginBottom: "10px" }}>
            <input
              className="form-check-input"
              type="checkbox"
              checked={props.bAnswer.includes(option._id)}
              onChange={() => props.bAnswerChanged(option._id)}
            />
            <p className="mb-0">{option.label}</p>
          </div>
        );
      })}
      <br />
      <Input
        disabled={false}
        colSize={12}
        mdSize={12}
        rows={5}
        type="textArea"
        name="notes"
        placeholder="Any situation you would like to share? Any nuances?"
        value={props.notes}
        onChange={(value) => props.setNotes(value)}
      />
    </>
  );
}

export default T360BAttempt;
