import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/appContext";
import { FaStream, FaLaptop, FaFileAlt, FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

import Footer from "../../layout/footer";
import PageTitle from "../../layout/pageTitle";
import HelpSidebar from "../../layout/helpSidebar";
import Button from "../../common/button";
import Carousel from "../help/carousel";
import Logo1 from "../../../assets/img/transhives-logo.png";

import TransHivesFlyerNew from "./TransHivesFlyerNew.webp";
import ResearchLogos from "./researchLogos.webp";
import WhyLoveTransHives01 from "./whyLoveTransHives01.webp";
import WhyLoveTransHives02 from "./whyLoveTransHives02.webp";
import WhyLoveTransHives03 from "./whyLoveTransHives03.webp";

function ListItem(props) {
  return (
    <>
      <div className="col-lg-6 col-md-12">{props.listItem}</div>
    </>
  );
}

function WhyTransHives() {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const highlightStyle = {
    backgroundColor: "#191970",
    color: "white",
    fontWeight: "bold",
    padding: "3px 5px",
    border: "1px solid #191970",
    borderRadius: "10px",
    display: "inline-block",
  };

  const cognitiveSkills = [
    "Problem solving",
    "Structured thinking",
    "Logical understanding",
    "Reasoning",
    "Mathematics",
    "Processing",
    "Judgement",
    "Memory",
    "Time management",
    "Confidence",
    "Perseverance",
    "Sustained attention",
    "Selective attention",
    "Language comprehension",
    "Business acumen",
  ];
  const behaviouralSkills = [
    "Effective communication",
    "Time management",
    "Adaptability - involuntary",
    "Adaptability - voluntary",
    "Leadership",
    "Influencing and help",
    "Creativity and ideas",
    "Teamwork",
    "Conflict management - manager",
    "Conflict management - team",
    "Work ethic",
    "Motivation",
  ];
  const antiCheating = [
    "System interactions",
    "Structure of algorithm",
    "Question format",
    "Answer format",
    "Warnings with reasons",
    "Second test at premises",
    "Reviews and blacklisting",
  ];

  const jobCreate = [
    {
      src: WhyLoveTransHives01,
      content: (
        <>
          <h3>
            {!isMobile && (
              <>
                <br />
                <br />
              </>
            )}
            Simply post a job in seconds.
            <br />
            No long form filling at all.
          </h3>
          <p>(Slide 1 of 3)</p>
        </>
      ),
    },
    {
      src: WhyLoveTransHives02,
      content: (
        <>
          <h3>
            {!isMobile && (
              <>
                <br />
                <br />
              </>
            )}
            Receive applications
            <br />
            from various sources.
          </h3>
          <p>(Slide 2 of 3)</p>
        </>
      ),
    },
    {
      src: WhyLoveTransHives03,
      content: (
        <>
          <h3>
            Our Magic Applied!
            <br />
            HR Superpowers Supercharged!
          </h3>
          <p>
            The software will send invites, receive data, apply intelligence and
            do everything in between to proactively generate a ranked candidate
            list. This will be generated based on both cognitive skills and the
            unique fit to the future manager and team members.
            <br />
            <br />
            Now the HR executives can either hire the top-ranked candidate or
            continue the remaining steps of the hiring process by looking at
            their detailed profile. HR is truly empowered to do more
            human-centric tasks that HR superheroes do!
          </p>
        </>
      ),
    },
  ];

  const { setCurrentPageID } = useContext(AppContext);

  useEffect(() => {
    setCurrentPageID("Why-Love-TransHives");
    document.title = "Why Love TransHives | TransHives";
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="row">
          <div className="col-8">
            <br />
            <div className="container-fluid py-4">
              <img src={Logo1} width="100%" />
            </div>
          </div>
        </div>
      ) : (
        <HelpSidebar />
      )}
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <>
          {!isMobile && <br />}
          <div className="container-fluid py-4">
            <PageTitle
              title={"Why Love TransHives?"}
              helpText="Because it simply is the best for everyone - organisations, HR executives and candidates!"
            />

            <div className="row" id="section1">
              <div className="col-lg-3 col-md-12">
                <ul class="nav flex-column bg-white border-radius-lg p-3">
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    Content Outline
                  </p>
                  <li class="nav-item" style={{ marginTop: "10px" }}>
                    <a
                      class="nav-link text-body d-flex align-items-center"
                      data-scroll
                      href="#section1"
                    >
                      <FaStream />
                      <span class="text-sm" style={{ marginLeft: "10px" }}>
                        At a Glance
                      </span>
                    </a>
                  </li>
                  <li class="nav-item pt-2">
                    <a
                      class="nav-link text-body d-flex align-items-center"
                      data-scroll
                      href="#section2"
                    >
                      <FaLaptop />
                      <span class="text-sm" style={{ marginLeft: "10px" }}>
                        How it Works
                      </span>
                    </a>
                  </li>
                  <li class="nav-item pt-2">
                    <a
                      class="nav-link text-body d-flex align-items-center"
                      data-scroll
                      href="#section3"
                    >
                      <FaFileAlt />
                      <span class="text-sm" style={{ marginLeft: "10px" }}>
                        Detailed Info
                      </span>
                    </a>
                  </li>
                </ul>
                <Link to="/explore" target="_blank">
                  <Button
                    color="primary"
                    marginTop="10px"
                    type={"withBgNoIcon"}
                    colSize={12}
                    text="Try FREE (No card needed)"
                    onClick={() => console.log()}
                  />
                </Link>
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    {isMobile && <br />}
                    <h3>
                      <b>At a Glance</b>
                    </h3>
                    <h5>Existing HRTech</h5>
                    <p className="mb-0">
                      There are so many HRTech tools out there. All they can do
                      is automate moving data back and forth from/to candidates
                      and HR executives. That is it. These can be lied to and
                      even if everyone is honest, they simply cannot detect good
                      hires. Some claim to use AI/data analytics but all they do
                      is automate the read/write of text and show data in
                      charts.
                      <br />
                      <br />
                      The result of using these 'dumb' tools? A plague of
                      misfits with over 80%
                      <sup>
                        <Link
                          to="https://s3.eu-west-2.amazonaws.com/transhives.com/research/Only+1+in+5+New+Hires+Are+Successful.+Here's+How+to+Get+Your+Team+Back+on+Track+After+a+Bad+Hire+_+Inc.com.pdf"
                          target="_blank"
                        >
                          [1]
                        </Link>
                        <Link
                          to="https://s3.eu-west-2.amazonaws.com/transhives.com/research/Why+New+Hires+Fail+(The+Landmark+_Hiring+For+Attitude_+Study).pdf"
                          target="_blank"
                        >
                          [2]
                        </Link>
                      </sup>{" "}
                      ending up being bad hires costing about 30%
                      <sup>
                        <Link
                          to="https://s3.eu-west-2.amazonaws.com/transhives.com/research/The+Cost+of+a+Bad+Hire_+Human+Resources+-+Northwestern+University.pdf"
                          target="_blank"
                        >
                          [3]
                        </Link>
                      </sup>{" "}
                      of their salary. What is worse is the real, 10x
                      <sup>
                        <Link
                          to="https://s3.eu-west-2.amazonaws.com/transhives.com/research/Recruitment_and_recovery_report_2021_condensed.pdf"
                          target="_blank"
                        >
                          [4]
                        </Link>
                        <Link
                          to="https://s3.eu-west-2.amazonaws.com/transhives.com/research/Happy+workers+are+13%25+more+productive+_+University+of+Oxford.pdf"
                          target="_blank"
                        >
                          [5]
                        </Link>
                      </sup>{" "}
                      cost of losing out on the synergies of strategically
                      chosen hires working together.
                      <br />
                      <br />
                      HR executives work so hard to hire them. But ultimately,
                      candidates will end up hating their jobs. Organisations
                      will be left with suboptimal talent. It is a
                      lose-lose-lose for everyone.
                    </p>
                    <br />
                    <h5>
                      TransHives - what's AWESOME?
                      <br />
                      Intelligent ATS + Evaluations Suite
                    </h5>
                    <p className="mb-0">
                      TransHives is fundamentally different. We don't just
                      automate problems. We solve them. Our intelligent ATS +
                      evaluations suite has seven (7) layers of intelligence to
                      avoid misfits & hire strategic fits.
                      <br />
                      <br />
                      <b>Why Strategic Fits?</b>
                      <br />
                      For organisations, this means up to 7x
                      <sup>
                        <Link
                          to="https://s3.eu-west-2.amazonaws.com/transhives.com/research/attracting-and-retaining-the-right-talent-nov-2017.pdf"
                          target="_blank"
                        >
                          [6]
                        </Link>
                        <Link
                          to="https://s3.eu-west-2.amazonaws.com/transhives.com/research/Perfect_match_-_Making_the_right_hire_and_the_cost_of_getting_it_wrong_2017.pdf"
                          target="_blank"
                        >
                          [7]
                        </Link>
                      </sup>{" "}
                      productivity from excellent individual performers and
                      synergies of strategically chosen hires working together.
                      For employees, this means happier, more fulfilling
                      careers. For HR, this means a job well done and more time
                      spent doing more human-centric, value-adding work.
                      TransHives is a win-win-win for everyone!
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    {isMobile && <br />}
                    <img
                      src={TransHivesFlyerNew}
                      loading="lazy"
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        border: "1px solid #191970",
                      }}
                      width="100%"
                    />
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <Link to="/explore" target="_blank">
                          <Button
                            color="primary"
                            marginTop="10px"
                            type={"withBgNoIcon"}
                            colSize={12}
                            text="Try FREE (No card)"
                            onClick={() => console.log()}
                          />
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <a href={TransHivesFlyerNew} download>
                          <Button
                            marginTop="10px"
                            type={"withBgNoIcon"}
                            colSize={12}
                            text="Download Flyer"
                            onClick={() => console.log("")}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <hr className="horizontal dark mt-0" />
            <div className="col-12" id="section2">
              <h3>
                <b>How it Works</b>
              </h3>
              <h5>
                Blissful user experience.
                <br />
                Backed by intelligence behind the scenes.
              </h5>
              <Carousel carouselItems={jobCreate} />
            </div>

            <hr className="horizontal dark mt-0" />
            <div className="row" id="section3">
              <div className="col-12">
                <h3>
                  <b>Detailed Info</b>
                </h3>
                <h5>
                  Top 7 reasons to just{" "}
                  <font color="red">
                    LOVE <FaHeart />
                  </font>{" "}
                  TransHives.
                </h5>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h5>1. Meaningful cognitive evaluation</h5>
                <p className="mb-0">
                  Existing cognitive evaluations are either IQ tests or
                  'pseudoscience' abstract games. They are simply limited to
                  evaluating pattern recognition and have little relevance to
                  the workplace.
                  <br />
                  <br />
                  TransHives is fundamentally different. We use real-world
                  scenarios{" "}
                  <span style={highlightStyle}>
                    relevant to the workplace
                  </span>{" "}
                  and benchmark with top performers across 15 dimensions.
                  <br />
                  <br />
                  For organisations, this means talent who are genuinely
                  intelligent. For HR executives, this means actually useful
                  meaningful insights. For candidates, this means the
                  opportunity to showcase their real intelligence without
                  prejudice from tests that test irrelevant skills.
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                {isMobile && <br />}
                <p className="mb-0">
                  Cognitive dimensions:
                  <div className="row">
                    {cognitiveSkills.map((skill, index) => (
                      <ListItem listItem={index + 1 + ". " + skill} />
                    ))}
                  </div>
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-12">
                <h5>2. Tailored behavioural evaluation</h5>
              </div>
              <div className="col-lg-6 col-md-12">
                <p className="mb-0">
                  Existing 'personality' tests were simply recreational quizzes
                  that were never meant to be taken seriously. However,
                  organisations have no choice but to use these as that is what
                  is currently offered. The worst part about that is, that they
                  act as if there is some objective one type of right
                  personality for every company when it simply is not true.
                  <br />
                  <br />
                  TransHives is fundamentally different. We don't do
                  'personality' tests. We use data from existing employees and
                  perform a tailored evaluation to find the{" "}
                  <span style={highlightStyle}>unique fit</span> for the{" "}
                  <span style={highlightStyle}>future manager and team</span>{" "}
                  members across 12 dimensions.
                  <br />
                  <br />
                  For the organisation, this means strategically chosen teams.
                  For HR executives, this means spotting candidates that fit
                  well into their new roles. For candidates, this means the
                  opportunity to truly be themselves without prejudice from
                  abstract personality tests.
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                {isMobile && <br />}
                <p className="mb-0">
                  Behavioural dimensions:
                  <div className="row">
                    {behaviouralSkills.map((skill, index) => (
                      <ListItem listItem={index + 1 + ". " + skill} />
                    ))}
                  </div>
                </p>
                <br />
                <p className="mb-0">Inspired by research from:</p>
                <div className="row">
                  <div className="col-lg-5 col-md-5">
                    <img
                      src={ResearchLogos}
                      loading="lazy"
                      width="100%"
                      style={{
                        marginTop: "10px",
                        borderRadius: "10px",
                        overflow: "hidden",
                        border: "1px solid #191970",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h5>3. Blissful user experience</h5>
                <p className="mb-0">
                  Existing software is just a nightmare of form-filling for both
                  HR teams and candidates. Everyone just hates it but has no
                  other options.
                  <br />
                  <br />
                  TransHives is fundamentally different.{" "}
                  <span style={highlightStyle}>
                    Build with user experience in mind
                  </span>
                  , there is almost little or no form filling needed. Check out{" "}
                  <Link to="/help">
                    <u>Guide 1</u>
                  </Link>{" "}
                  on posting a job and{" "}
                  <Link to="/help">
                    <u>Guide 5</u>
                  </Link>{" "}
                  on applying for a job to witness the magic.
                  <br />
                  <br />
                  For organisations, this means fewer candidates will abandon
                  the applications when applying online. For HR executives, this
                  means spending more time doing value-adding tasks only humans
                  can do and less time doing boring forms. For candidates, this
                  means not having to type their resume on a form and actually
                  enjoy applying.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-12">
                <h5>4. Built-in anti-cheating measures</h5>
              </div>
              <div className="col-lg-6 col-md-12">
                <p className="mb-0">
                  Because of the predatory nature of the traditional quizzes
                  (above), candidates are often tempted to lie in them.
                  <br />
                  <br />
                  TransHives is fundamentally different. Everything from how the
                  questions and answers are phrased, to how the algorithm works
                  along with further blacklisting options makes sure{" "}
                  <span style={highlightStyle}>honesty always wins.</span>
                  <br />
                  <br />
                  For organisations, this means a truly good set of candidates.
                  For HR executives, this means evaluations they can rely on.
                  For candidates, this means the ability to be themselves
                  without being stressed about finding ways to cheat.
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                {isMobile && <br />}
                <p className="mb-0">
                  This is achieved through:
                  <div className="row">
                    {antiCheating.map((item, index) => (
                      <ListItem listItem={index + 1 + ". " + item} />
                    ))}
                  </div>
                  <br />
                  (E.g.: An example would be, in our tailored behavioural
                  evaluation, someone who shares their true personality will be
                  better prioritised when compared with their future coworkers
                  than someone who manufactures a personality they think is
                  perfect.)
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h5>5. Easy integration and migration/transition</h5>
                <p className="mb-0">
                  Existing software has complicated APIs to set up, takes a long
                  time and needs expert consultation to integrate or
                  migrate/transition. If you have multiple sources you get
                  resumes from, it is a similar nightmare to enter your data
                  into the software.
                  <br />
                  <br />
                  Not with TransHives. TransHives offers a range of ways to
                  integrate or migrate/transition and the easiest method is
                  simply{" "}
                  <span style={highlightStyle}>
                    as easy as copy-pasting
                  </span>{" "}
                  candidate email addresses. That's it! The software will handle
                  everything else for you. HR executives love these superpowers.
                  TransHives is truly built with all users in mind.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h5>6. How we perform analytics</h5>
                <p className="mb-0">
                  Existing HRTech tools don't really perform analytics. They
                  just show existing numbers like how many candidates applied in
                  the past seven days and so on in charts and show trendlines.
                  While this looks cool, it is of limited use to select a good
                  candidate.
                  <br />
                  <br />
                  TransHives is fundamentally different. With the above
                  features, we have CEO-like conviction built in to{" "}
                  <span style={highlightStyle}>actually 'analyse'</span> the
                  candidates.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h5>7. How we use AI</h5>
                <p className="mb-0">
                  Existing HRTech tools use AI to simply fast-read resumes and
                  generate job descriptions. This is just the pinnacle of
                  mindless automation. There are plenty of cases where this
                  overlooks top-quality candidates and prioritises someone who
                  lies in their resume/CV.
                  <br />
                  <br />
                  TransHives is fundamentally different. We use AI to genuinely{" "}
                  <span style={highlightStyle}>improve</span> our software and
                  algorithms recursively (using anonymised data) so you receive{" "}
                  <span style={highlightStyle}>more value for you</span>r time
                  and effort as we go on.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div
                className="col-lg-6 col-md-12"
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  border: "1px solid #191970",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <h5>Let's EXPLORE</h5>
                <p className="mb-0">
                  ...and that is how we help organisations to have the talent
                  they admire, empower HR executives to do meaningful work (not
                  boring form filling) and help candidates to be at
                  organisations they love.
                  <br />
                  <br />
                </p>
                <div className="row">
                  <div className="col-6">
                    <p className="mb-0">
                      Curious?
                      <br />
                      Let's explore the software.
                    </p>
                  </div>
                  <div className="col-6">
                    <Link to="/explore" target="_blank">
                      <Button
                        color="primary"
                        marginTop="10px"
                        type={"withBgNoIcon"}
                        colSize={12}
                        text="Try FREE (No card needed)"
                        onClick={() => console.log()}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      </main>
    </>
  );
}

export default WhyTransHives;
