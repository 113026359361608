import React, { useEffect, useState, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";

import { AppContext } from "../../../context/appContext";
import Auth from "../../auth/auth";

import OrgSidebar from "../../layout/orgSidebar";
import Header from "../../layout/header";
import PageTitle from "../../layout/pageTitle";
import Footer from "../../layout/footer";
import Alert from "../../common/alert";
import TeamInputs from "./teamInputs";

function TeamCreate() {
  const { getSession, isShowAuth, setCurrentPageID, setIsShowAuth } =
    useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  useEffect(() => {
    setCurrentPageID("teamCreate");
    document.title = "Create Team | TransHives";
    if (session.authLevel >= 3) fetchData(session);
    else setIsShowAuth(true);
  }, []);

  async function fetchData() {}

  return (
    <>
      <OrgSidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        {alertType && (
          <Alert
            alertType={alertType}
            alertText={alertText}
            setAlertType={() => setAlertType("")}
            setAlertText={() => setAlertText("")}
          />
        )}
        <Header />{" "}
        {session.isActive && session.authLevel >= 3 && (
          <>
            <LoadingOverlay active={isLoading} spinner text="Loading...">
              <div className="container-fluid py-4">
                <PageTitle title={"Create a Team"} />
                <div className="row">
                  <div className="col-lg-10 col-md-12">
                    <div className="card-body pt-0">
                      <TeamInputs
                        type="create"
                        setIsLoading={(value) => setIsLoading(value)}
                        setAlertType={(value) => setAlertType(value)}
                        setAlertText={(value) => setAlertText(value)}
                        teamCreated={(value) => teamCreated(value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </LoadingOverlay>
            <Footer />
          </>
        )}
      </main>
      {isShowAuth && (
        <Auth isFromApply={false} onAuth={(session) => fetchData(session)} />
      )}
    </>
  );

  function teamCreated() {} // optional
}

export default TeamCreate;
