import React from "react";
import { FaEdit, FaRegCheckCircle } from "react-icons/fa";

function ProfileBasicInfo(props) {
  return (
    <div className="col-lg-4 col-md-12">
      <div className="card h-100">
        <div className="card-header pb-0 p-3">
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">
              <h6 className="mb-0">Basic Information</h6>
            </div>
            <div className="col-md-4 text-end">
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                  paddingLeft: "0px",
                }}
                onClick={() => props.setModalToShow("basicInfo")}
              >
                <FaEdit />
              </button>
            </div>
          </div>
        </div>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-3">Name: </div>
            <div className="col-9">
              {props.user.firstName} {props.user.lastName}
            </div>
            <div className="col-3">Email: </div>
            <div className="col-9">
              {props.user.email} <FaRegCheckCircle />
            </div>
            <div className="col-3">Mobile: </div>
            <div className="col-9">{props.user.mobile}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileBasicInfo;
