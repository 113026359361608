import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../context/appContext";
import { useNavigate } from "react-router";
import "react-phone-input-2/lib/high-res.css";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../../utils/axiosConfig";

import Alert from "../../common/alert";

import logo from "../../../assets/img/transhives-logo.png";
import bgImage from "../../../assets/img/curved-images/curved7.jpg";
import CreatePassword from "./createPassword";
import TeamInvite from "./teamInvite";
import InvalidInvite from "./invalidInvite";
import LinkSuccess from "./linkSuccess";

function Invite() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const { setCurrentPageID, handleSignedIn, handleSignOut } =
    useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const key = urlParams.get("key");
  const cat = urlParams.get("cat");

  const [optionToDisplay, setOptionToDisplay] = useState("");

  useEffect(() => {
    setCurrentPageID("invite");
    document.title = "Invitation | TransHives";
    if (email !== null && key !== null && cat === "1") validateInvite();
    else if (email !== null && key !== null && cat === "2") linkAccounts();
    else navigate("/profile");
  }, []);

  async function validateInvite() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
      key: key,
    };

    try {
      const response = await axiosInstance.post(`/auth/invite`, body);

      if (response.data.isValidInvite === true) {
        if (response.data.type === "t360" || response.data.type === "dual")
          setOptionToDisplay("create");
        else if (response.data.type === "team") setOptionToDisplay("team");
      } else setOptionToDisplay("invalid");

      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setIsLoading(false);
    }
  }

  async function linkAccounts() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
      key: key,
    };

    try {
      const response = await axiosInstance.post(`/user/linvite/accept`, body);

      if (response.data.isValidInvite === true) {
        const authHeader = response.headers.authorization;
        const token = authHeader.split(" ")[1];

        const session = {
          isActive: true,
          authLevel: 3,
          email: email,
          token: token,
          resumeName: "",
        };
        handleSignedIn(session);

        setOptionToDisplay("linkSuccess");
      } else setOptionToDisplay("invalid");

      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setIsLoading(false);
    }
  }

  return (
    <main className="main-content  mt-0">
      <div
        className="page-header min-vh-100"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container">
          <div className="row justify-content-center">
            <div className={isMobile ? "col-12" : "col-4"}>
              <LoadingOverlay active={isLoading} spinner text="Loading...">
                <div className="card">
                  {alertType && (
                    <Alert
                      alertType={alertType}
                      alertText={alertText}
                      setAlertType={() => setAlertType("")}
                      setAlertText={() => setAlertText("")}
                    />
                  )}
                  <div className="card-body px-lg-5 py-lg-5 text-center">
                    <center>
                      <div className="card-header text-center pt-4">
                        <img src={logo} height="50px" />
                      </div>
                    </center>
                    {renderContent()}
                  </div>
                </div>
              </LoadingOverlay>
              <div className="col-12">
                <br />
                <center>
                  <p className="mb-0">
                    <a
                      href="/notices"
                      className="nav-link text-muted"
                      target="_blank"
                    >
                      <font color="white">
                        TransHives © 2022-
                        {new Date().getFullYear()} | Notices
                      </font>
                    </a>
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );

  function renderContent() {
    if (optionToDisplay === "create") {
      return (
        <CreatePassword
          email={email}
          inviteKey={key}
          setAlertType={(value) => setAlertType(value)}
          setAlertText={(value) => setAlertText(value)}
          setIsLoading={(value) => setIsLoading(value)}
        />
      );
    } else if (optionToDisplay === "team") {
      return (
        <TeamInvite
          email={email}
          inviteKey={key}
          setOptionToDisplay={(value) => setOptionToDisplay(value)}
          setAlertType={(value) => setAlertType(value)}
          setAlertText={(value) => setAlertText(value)}
          setIsLoading={(value) => setIsLoading(value)}
        />
      );
    } else if (optionToDisplay === "linkSuccess") {
      return <LinkSuccess />;
    } else if (optionToDisplay === "invalid") {
      return <InvalidInvite />;
    }
  }
}

export default Invite;
