import React from "react";

import { SmartH6, SmartP } from "../../common/text";
import { getDate, isWithinMonth } from "../../../utils/utils";
import Button from "../../common/button";

function JobCard(props) {
  return (
    <div className="col-lg-4 col-md-12" style={{ marginTop: "15px" }}>
      <div className="card h-100">
        <div className="card-header pb-0 p-3">
          <div className="row">
            <div className="col-9">
              <SmartH6
                _id={"jobName-" + props.team._id}
                text={props.team.teamName}
                maxLength={25}
              />
              <SmartP
                _id={"location-" + props.team._id}
                text={"Leader(s): " + props.team.leadersStringShort}
                maxLength={25}
              />
              <SmartP
                _id={"location-" + props.team._id}
                text={"Member(s): " + props.team.membersStringShort}
                maxLength={25}
              />
            </div>
            <Button
              type={"aArrow"}
              colSize={3}
              text={"View"}
              onClick={() => props.viewTeam()}
            />
          </div>
        </div>

        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <p
                className="text-secondary font-weight-bold text-xs mt-1 mb-0"
                style={{ marginBottom: "15px" }}
              >
                Created: {getDate(props.team.createdOn)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobCard;
