import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import { FaTimesCircle } from "react-icons/fa";
import { AppContext } from "../../context/appContext";

import Button from "../common/button";

function JobInfoModal(props) {
  const { getSession } = useContext(AppContext);
  const session = getSession();

  const navigate = useNavigate();
  return (
    <Modal show={true} backdrop="static" centered>
      <Modal.Body>
        <div className="row">
          <div className="col-2"></div>
          {props.type && props.type === "careerPage" && (
            <div className="col-6"></div>
          )}
          {props.type && props.type === "careerPage" && (
            <>
              {session.authLevel === 4 ? (
                <Button
                  disabled={true}
                  type={"aArrow"}
                  colSize={2}
                  text={"Apply"}
                  onClick={() => props.setModalToShow("applyToJob")}
                />
              ) : (
                <Button
                  type={"aArrow"}
                  colSize={2}
                  text={"Apply"}
                  onClick={() => props.setModalToShow("applyToJob")}
                />
              )}
            </>
          )}
          {props.type && props.type === "viewJobs" && (
            <Button
              type={"noBgNoIcon"}
              colSize={4}
              icon={FaTimesCircle}
              text={"Mark As Complete"}
              onClick={() => props.markAsComplete()}
            />
          )}
          {props.type && props.type === "viewJobs" && (
            <Button
              type={"withBgNoIcon"}
              colSize={4}
              icon={FaTimesCircle}
              text={"View Candidates"}
              onClick={() => navigate("/candidates?jobID=" + props.job._id)}
            />
          )}

          <Button
            type={"noBgWithIcon"}
            colSize={2}
            icon={FaTimesCircle}
            text="Close"
            onClick={() => props.closeModal()}
          />
        </div>

        <div className="row">
          <div className="col-12">
            <h6 className="mb-0">{props.job.jobName}</h6>
            <p className="mb-0"> {props.job.location}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <br />
            <center>
              <span className="badge bg-gradient-primary ms-auto">
                Min. Education: {props.job.minEdu.label}
              </span>{" "}
              <span className="badge bg-gradient-primary ms-auto">
                Min. Experience: {props.job.minExp.label}
              </span>
            </center>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-12">
            <p className="text-sm">{props.job.desc}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default JobInfoModal;
