import React from "react";
import { Link } from "react-router-dom";

function InvalidInvite(props) {
  return (
    <div className="row">
      <p className="mb-0">
        This invite appears to be invalid. Try again! If you have already
        created a password,{" "}
        <Link to="/profile">
          <u>click here</u>
        </Link>{" "}
        to continue/login. <br />
        <br />
        If you think something is wrong, contact support@help.transhives.com.
      </p>
    </div>
  );
}

export default InvalidInvite;
