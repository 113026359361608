import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/appContext";
import { useNavigate } from "react-router";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../utils/axiosConfig";
import { Link } from "react-router-dom";

import logo from "../../assets/img/transhives-logo.png";
import bgImage from "../../assets/img/curved-images/curved7.jpg";
import Input from "../common/input";
import Button from "../common/button";
import Alert from "../common/alert";

function ResetPwd() {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const navigate = useNavigate();

  const { setCurrentPageID } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const key = urlParams.get("key");

  const [optionToDisplay, setOptionToDisplay] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    setCurrentPageID("resetPwd");
    document.title = "Reset Password | TransHives";
    if (email !== null && key !== null) validateResetPwd();
    else navigate("/profile");
  }, []);

  async function validateResetPwd() {
    setIsLoading(true);

    const body = {
      email: email.trim(),
      key: key,
    };

    try {
      const response = await axiosInstance.post(
        `/auth/resetPwd/validate`,
        body
      );

      if (response.data.isValid === true) setOptionToDisplay("resetPwd");
      else setOptionToDisplay("invalid");
    } catch (error) {
      setAlertType("error");

      if (error.response && error.response.status === 429)
        setAlertText("Too many requests from your network!");
      else setAlertText(error.message);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setIsLoading(false);
  }

  async function resetPwd() {
    const regexPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (password === "" || !regexPattern.test(password)) {
      setAlertType("info");
      setAlertText(
        "A password needs at least eight characters, one uppercase letter, one lowercase letter, one number and one special character."
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (password !== confirmPassword) {
      setAlertType("error");
      setAlertText("Passwords don't match. Please try again!");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setIsLoading(true);

      const body = {
        email: email.trim(),
        key: key,
        password: password,
      };

      try {
        const response = await axiosInstance.put(`/auth/resetPwd`, body);
        if (response.data.isOrgUser) setOptionToDisplay("resetPwdDoneOrg");
        else setOptionToDisplay("resetPwdDoneUser");
      } catch (error) {
        setAlertType("error");

        if (error.response && error.response.status === 429)
          setAlertText("Too many requests from your network!");
        else setAlertText(error.message);

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setIsLoading(false);
    }
  }

  return (
    <main className="main-content  mt-0">
      <div
        className="page-header min-vh-100"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container">
          <div className="row justify-content-center">
            <div className={isMobile ? "col-12" : "col-4"}>
              <LoadingOverlay active={isLoading} spinner text="Loading...">
                <div className="card">
                  {alertType && (
                    <Alert
                      alertType={alertType}
                      alertText={alertText}
                      setAlertType={() => setAlertType("")}
                      setAlertText={() => setAlertText("")}
                    />
                  )}
                  <div className="card-body px-lg-5 py-lg-5 text-center">
                    <center>
                      <div className="card-header text-center pt-4">
                        <img src={logo} height="50px" />
                      </div>
                    </center>
                    {renderContent()}
                  </div>
                </div>
              </LoadingOverlay>
              <div className="col-12">
                <br />
                <center>
                  <p className="mb-0">
                    <a
                      href="/notices"
                      className="nav-link text-muted"
                      target="_blank"
                    >
                      <font color="white">
                        TransHives © 2022-
                        {new Date().getFullYear()} | Notices
                      </font>
                    </a>
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );

  function renderContent() {
    if (optionToDisplay === "resetPwd") {
      return (
        <div className="row">
          <p className="mb-0">Let's create a new password.</p>
          <br />
          <br />
          <br />
          <Input
            disabled={true}
            colSize={12}
            mdSize={12}
            type="text"
            name="email"
            placeholder="Email Address"
            value={email}
          />
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(value) => setPassword(value)}
          />
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(value) => setConfirmPassword(value)}
          />
          <Button
            type={"withBgNoIcon"}
            colSize={12}
            text="Reset Password"
            onClick={() => resetPwd()}
          />
        </div>
      );
    } else if (optionToDisplay === "invalid") {
      return (
        <div className="row">
          <p className="mb-0">
            This link appears to be invalid. Try again! If you have already
            reset your password,{" "}
            <Link to="/">
              <u>click here</u>
            </Link>{" "}
            to continue/login. <br />
            <br />
            If you think something is wrong, contact
            support@help.transhives.com.
          </p>
        </div>
      );
    } else if (optionToDisplay === "resetPwdDoneOrg") {
      return (
        <div className="row">
          <p className="mb-0">
            You have successfully reset your password.{" "}
            <Link to="/dashboard">
              <u>Click here</u>
            </Link>{" "}
            to continue/login. We are excited to have you back! <br />
          </p>
        </div>
      );
    } else if (optionToDisplay === "resetPwdDoneUser") {
      return (
        <div className="row">
          <p className="mb-0">
            You have successfully reset your password.{" "}
            <Link to="/profile">
              <u>Click here</u>
            </Link>{" "}
            to continue/login. We are excited to have you back! <br />
          </p>
        </div>
      );
    }
  }
}

export default ResetPwd;
