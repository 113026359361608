import React from "react";
import Select from "react-select";

function Input(props) {
  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#cb0c9f" : "#d2d6da",
      boxShadow: state.isFocused ? "#cb0c9f" : null,
      "&:hover": {
        borderColor: state.isFocused ? "#cb0c9f" : "#d2d6da",
      },
      borderRadius: "0.5rem",
      padding: "1px",
      fontWeight: "400",
      lineHeight: "1.4rem",
      backgroundClip: "padding-box",
    }),
  };

  return (
    <div className={"col-lg-" + props.colSize + " col-md-" + props.mdSize}>
      <div className="form-group">{renderContent()}</div>
    </div>
  );

  function renderContent() {
    if (props.type === "text") {
      return (
        <>
          {props.helpText && <label class="form-label">{props.helpText}</label>}
          <input
            disabled={props.disabled}
            type="text"
            name={props.name}
            placeholder={props.placeholder}
            className="form-control"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        </>
      );
    } else if (props.type === "number") {
      return (
        <>
          {props.helpText && <label class="form-label">{props.helpText}</label>}
          <input
            disabled={props.disabled}
            type="number"
            name={props.name}
            placeholder={props.placeholder}
            className="form-control"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        </>
      );
    } else if (props.type === "password") {
      return (
        <input
          disabled={props.disabled}
          type="password"
          name={props.name}
          placeholder={props.placeholder}
          className="form-control"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      );
    } else if (props.type === "select") {
      return (
        <>
          {props.helpText && <label class="form-label">{props.helpText}</label>}
          <Select
            disabled={props.disabled}
            name={props.name}
            placeholder={props.placeholder}
            options={props.options}
            styles={selectStyle}
            isClearable="true"
            value={props.value}
            onChange={(value) => props.onChange(value)}
          />
        </>
      );
    } else if (props.type === "textArea") {
      return (
        <>
          {props.helpText && <label class="form-label">{props.helpText}</label>}
          <textarea
            disabled={props.disabled}
            name={props.name}
            placeholder={props.placeholder}
            className="form-control"
            rows={props.rows}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        </>
      );
    } else if (props.type === "dateTime") {
      return (
        <input
          disabled={props.disabled}
          type="datetime-local"
          name={props.name}
          placeholder={props.placeholder}
          className="form-control"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      );
    } else if (props.type === "file") {
      return (
        <>
          {props.helpText && <label class="form-label">{props.helpText}</label>}
          <input
            accept="application/pdf"
            disabled={props.disabled}
            type="file"
            name={props.name}
            placeholder={props.placeholder}
            className="form-control"
            value={props.value}
            onChange={(e) => props.onChange(e.target.files[0])}
          />
        </>
      );
    }
  }
}

export default Input;
