import React from "react";
import Input from "../../common/input";

function T360Question(props) {
  return (
    <div className="col-lg-6 col-md-12">
      <div className="card-body px-lg-4">
        <div className="card-header pt-4">
          <h4 className="mb-0">Question {props.qLevel}</h4>
          <br />

          <p className="mb-0">
            {props.question.text.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>

          {props.qLevel <= 12 && (
            <p className="mb-0">
              <br />
              (You can pick multiple answers.
              <br />
              Remember to be authentic. This is NOT a personality test. <br />
              This is about finding matching teams and candidates. So, don't
              worry about manufacturing a personality you think is perfect. Have
              fun!)
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default T360Question;
