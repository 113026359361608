import React from "react";
import { AuthProvider } from "./context/appContext";
import { Route, Routes } from "react-router-dom";

import CareerPage from "./components/pages/careerPage/careerPage";
import Invite from "./components/pages/invite/invite";
import Profile from "./components/pages/profile/profile";
import ProfileCreate from "./components/pages/profile/profileCreate";
import T360 from "./components/pages/t360/t360";
import Applications from "./components/pages/applications/applications";

import DashboardCreate from "./components/pages/dashboard/dashboardCreate";
import Dashboard from "./components/pages/dashboard/dashboard";
import Jobs from "./components/pages/jobs/jobs";
import JobCreate from "./components/pages/jobs/jobCreate";
import Candidates from "./components/pages/candidates/candidates";
import TeamCreate from "./components/pages/teams/teamCreate";
import Teams from "./components/pages/teams/teams";
import Notices from "./components/legal/notices";
import ResetPwd from "./components/auth/resetPwd";
import Home from "./components/pages/home/home";
import Help from "./components/pages/help/help";
import WhyTransHives from "./components/pages/why-TransHives/why-TransHives";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />

        <Route path="/pages/:linkID" element={<CareerPage />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/create" element={<ProfileCreate />} />
        <Route path="/t360" element={<T360 />} />
        <Route path="/applications" element={<Applications />} />

        <Route path="/register" element={<DashboardCreate />} />
        <Route path="/explore/full" element={<DashboardCreate />} />
        <Route path="/explore" element={<DashboardCreate />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/jobs/create" element={<JobCreate />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/candidates" element={<Candidates />} />
        <Route path="/teams/create" element={<TeamCreate />} />
        <Route path="/teams" element={<Teams />} />

        <Route path="/notices" element={<Notices />} />
        <Route path="/resetPwd" element={<ResetPwd />} />

        <Route path="/hello" element={<WhyTransHives />} />
        <Route path="/Why-Love-TransHives" element={<WhyTransHives />} />
        <Route path="/help" element={<Help />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
