import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import { AppContext } from "../../../context/appContext";
import { FaCheck, FaRocket } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../../utils/axiosConfig";

import Alert from "../../common/alert";
import Input from "../../common/input";
import Button from "../../common/button";
import T360Schedule from "../../modals/t360Schedule";
import ShowSuccessModal from "../../modals/showSuccessModal";

import logo from "../../../assets/img/transhives-logo.png";
import bgImage from "../../../assets/img/curved-images/curved7.jpg";

function ProfileCreate() {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const navigate = useNavigate();
  const location = useLocation();

  const { getSession, setCurrentPageID } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [stepToShow, setstepToShow] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalToShow, setModalToShow] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");

  const [t360ScheduledFor, setT360ScheduledFor] = useState("");

  useEffect(() => {
    setCurrentPageID("profileCreate");
    document.title = "Create Profile | TransHives";
    if (location.state && location.state.stepToShow)
      setstepToShow(location.state.stepToShow);
    else navigate("/profile");

    setIsLoading(false);
  }, []);

  async function saveUser() {
    setIsLoading(true);

    const body = {
      user: { firstName: firstName, lastName: lastName, mobile: mobile },
    };

    try {
      await axiosInstance.post(`/user`, body, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      setstepToShow("step2");
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setIsLoading(false);
    }
  }

  async function scheduleT360() {
    setIsLoading(true);

    const body = {
      t360ScheduledFor: t360ScheduledFor,
    };

    try {
      await axiosInstance.post(`/user/t360Schedule`, body, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      setModalToShow("showSuccess");
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setIsLoading(false);
    }
  }

  return (
    <>
      <main className="main-content  mt-0">
        <div
          className="page-header min-vh-100"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className={isMobile ? "col-12" : "col-4"}>
                <LoadingOverlay active={isLoading} spinner text="Loading...">
                  <div className="card">
                    {alertType && (
                      <Alert
                        alertType={alertType}
                        alertText={alertText}
                        setAlertType={() => setAlertType("")}
                        setAlertText={() => setAlertText("")}
                      />
                    )}
                    <div className="card-body px-lg-5 py-lg-5 text-center">
                      <center>
                        <div className="card-header text-center pt-4">
                          <img src={logo} height="50px" />
                        </div>
                      </center>
                      {renderContent()}
                    </div>
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </main>

      {isShowModal && modalToShow === "t360Scheduler" && (
        <T360Schedule
          t360ScheduledFor={t360ScheduledFor}
          closeModal={() => setIsShowModal(false)}
          setT360ScheduledFor={(value) => setT360ScheduledFor(value)}
          scheduleT360={() => scheduleT360()}
        />
      )}

      {isShowModal && modalToShow === "showSuccess" && (
        <ShowSuccessModal
          header="Scheduled"
          text="You successfully scheduled your T360 adventure. We wish you all the best.
          You can close this pane and go to your profile."
          type="withRedirect"
          redirectTo="/profile"
        />
      )}
    </>
  );

  function renderContent() {
    if (stepToShow === "step1") {
      return (
        <div className="row">
          <p className="mb-0">
            Welcome to TransHives!
            <br />
            Let's create your TransHives profile.
            <br />
            (Step 1 of 2)
          </p>
          <br />
          <br />
          <br /> <br />
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="text"
            name="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={(value) => setFirstName(value)}
          />
          <Input
            disabled={false}
            colSize={12}
            mdSize={12}
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={(value) => setLastName(value)}
          />
          <div className={"col-12"}>
            <div className="form-group">
              <PhoneInput
                country={"gb"}
                className="form-group"
                placeholder="Mobile Number"
                value={mobile}
                onChange={(value) => setMobile(value)}
                inputStyle={{ height: "40px", width: "100%", fontSize: "1rem" }}
                dropdownStyle={{ maxHeight: "150px" }}
                enableSearch
              />
            </div>
          </div>
          <Button
            type={"withBgWithIcon"}
            colSize={12}
            icon={FaCheck}
            text="Done"
            onClick={() => saveUser(session)}
          />
        </div>
      );
    } else if (stepToShow === "step2") {
      return (
        <>
          <p className="mb-0">
            Start TransHives T360 now (~15 mins) or schedule to do it later. All
            the best!
          </p>
          <br />
          <Button
            type={"withBgWithIconRight"}
            colSize={12}
            icon={FaRocket}
            text="Start Now"
            onClick={() => navigate("/t360")}
          />
          {/* <Button
            type={"noBgNoIcon"}
            colSize={12}
            text="Schedule for Later"
            onClick={() => {
              setIsShowModal(true);
              setModalToShow("t360Scheduler");
            }}
          /> */}
        </>
      );
    }
  }
}

export default ProfileCreate;
