import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { FaChevronCircleRight } from "react-icons/fa";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../../utils/axiosConfig";

import { AppContext } from "../../../context/appContext";

import Alert from "../../common/alert";
import Button from "../../common/button";
import ShowSuccessModal from "../../modals/showSuccessModal";

import logo from "../../../assets/img/transhives-logo.png";
import bgImage from "../../../assets/img/curved-images/curved7.jpg";

import T360BAttempt from "./t360BAttempt";
import T360CAttempt from "./t360CAttempt";
import T360Question from "./t360Question";
import T360SubmitModal from "../../modals/t360SubmitModal";
import Auth from "../../auth/auth";
import T360Intro from "./t360Intro";

function T360() {
  const navigate = useNavigate();
  const {
    isShowAuth,
    setIsShowAuth,
    getSession,
    setCurrentPageID,
    handleSignOut,
  } = useContext(AppContext);
  const session = getSession();

  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");

  const [isShowModal, setIsShowModal] = useState(false);
  const [modalToShow, setModalToShow] = useState("confirmSubmit");
  const [isDisableButton, setIsDisableButton] = useState(false);

  const [t360Type, setT360Type] = useState("");
  const [qLevel, setQLevel] = useState(-1);
  const [question, setQuestion] = useState({ _id: "", text: "", options: [] });

  const [bAnswer, setBAnswer] = useState([]);
  const [cAnswer, setCAnswer] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    setCurrentPageID("t360");
    document.title = "TransHives T360 | TransHives";
    if (!session.authLevel >= 3) navigate("/profile");
  }, []);

  async function fetchData(qLevel) {
    setIsLoading(true);
    setIsDisableButton(true);

    const queryParams = {
      t360Type: t360Type,
      qLevel: qLevel,
    };

    try {
      const response = await axiosInstance.get(`/t360q`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
        params: queryParams,
      });

      setQuestion(response.data.question);
      setT360Type(response.data.t360Type);

      if (qLevel <= 12) {
        if (response.data.attempt.answer) {
          setBAnswer(response.data.attempt.answer);
          setNotes(response.data.attempt.notes);
        } else {
          setBAnswer([]);
          setNotes("");
        }
      } else {
        if (response.data.attempt.answer) {
          setCAnswer(response.data.attempt.answer);
          setNotes(response.data.attempt.notes);
        } else {
          setCAnswer("");
          setNotes("");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 600) {
        handleSignOut();
      } else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setTimeout(function () {
      setIsDisableButton(false);
      setIsLoading(false);
    }, 1000);
  }

  function bAnswerChanged(optionID) {
    let array = [...bAnswer];
    const index = array.indexOf(optionID);
    index !== -1 ? array.splice(index, 1) : array.push(optionID);
    setBAnswer(array);
  }

  function backClicked() {
    if (session.authLevel >= 3) {
      saveAttempt("fromBack");
    } else setIsShowAuth(true);
  }

  function nextClicked() {
    if (session.authLevel >= 3) {
      saveAttempt("fromNext");
    } else setIsShowAuth(true);
  }

  async function saveAttempt(fromWhat) {
    setIsLoading(true);

    let body = {
      attempt: {
        qLevel: qLevel,
        questionID: question._id,
        notes: notes,
      },
    };

    if (qLevel <= 12) {
      body.attempt.answer = bAnswer;
    } else {
      body.attempt.answer = cAnswer;
      body.attempt.options = question.options;
    }

    try {
      await axiosInstance.put(`/t360a/attempt`, body, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });

      if (fromWhat === "fromBack") {
        fetchData(qLevel - 1);
        setQLevel(qLevel - 1);
      } else if (fromWhat === "fromNext") {
        if (
          (t360Type === "team" && qLevel !== 12) ||
          (t360Type !== "team" && qLevel !== 17)
        ) {
          fetchData(qLevel + 1);
          setQLevel(qLevel + 1);
        } else {
          setIsShowModal(true);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 600) {
        handleSignOut();
      } else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  async function t360Submit() {
    setIsLoading(true);

    try {
      await axiosInstance.post(`/t360a/submit`, null, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });
      setModalToShow("showSuccess");
    } catch (error) {
      if (error.response && error.response.status === 600) {
        handleSignOut();
        setIsShowModal(false);
      } else if (error.response && error.response.status === 422) {
        setAlertType("error");
        setAlertText("All fields are required!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error.response && error.response.status === 429) {
        setAlertType("error");
        setAlertText("Too many requests from your network!");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setAlertType("error");
        setAlertText(error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      {qLevel > 0 ? (
        <main className="main-content  mt-0">
          <div
            className="page-header min-vh-100"
            style={{
              backgroundImage: `url(${bgImage})`,
            }}
          >
            <span className="mask bg-gradient-dark opacity-6"></span>
            <div className="container">
              <br />
              <div className="row justify-content-center">
                <div className="col-12">
                  <LoadingOverlay active={isLoading} spinner text="Loading...">
                    <div className="card">
                      {alertType && (
                        <Alert
                          alertType={alertType}
                          alertText={alertText}
                          setAlertType={() => setAlertType("")}
                          setAlertText={() => setAlertText("")}
                        />
                      )}
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="card-body px-lg-4"
                            style={{ paddingBottom: "0px" }}
                          >
                            <div
                              className="card-header"
                              style={{ paddingBottom: "0px" }}
                            >
                              <img src={logo} height="50px" />{" "}
                            </div>
                          </div>
                        </div>

                        <T360Question qLevel={qLevel} question={question} />

                        <div className="col-lg-6 col-md-12">
                          <div className="card-body px-lg-4">
                            <div className="card-header pt-4">
                              <h4 className="mb-0">Answer</h4>
                              <br />

                              {qLevel <= 12 ? (
                                <T360BAttempt
                                  question={question}
                                  bAnswer={bAnswer}
                                  notes={notes}
                                  bAnswerChanged={(value) =>
                                    bAnswerChanged(value)
                                  }
                                  setNotes={(value) => setNotes(value)}
                                />
                              ) : (
                                <T360CAttempt
                                  question={question}
                                  cAnswer={cAnswer}
                                  notes={notes}
                                  setCAnswer={(value) => setCAnswer(value)}
                                  setNotes={(value) => setNotes(value)}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className="card-body px-lg-5"
                            style={{ marginTop: "0px", paddingTop: "0px" }}
                          >
                            <div className="row">
                              <Button
                                disabled={
                                  isDisableButton
                                    ? isDisableButton
                                    : qLevel <= 1
                                    ? true
                                    : false
                                }
                                type={"noBgNoIcon"}
                                colSize={6}
                                text="Back"
                                onClick={() => backClicked()}
                              />
                              <Button
                                disabled={isDisableButton}
                                type={"withBgWithIconRight"}
                                colSize={6}
                                icon={FaChevronCircleRight}
                                text={
                                  t360Type === "team"
                                    ? qLevel < 12
                                      ? "Next"
                                      : "Submit"
                                    : qLevel < 17
                                    ? qLevel > 12
                                      ? "Next/Skip"
                                      : "Next"
                                    : "Submit"
                                }
                                onClick={() => nextClicked()}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="card-body px-lg-5">
                            <center>
                              <p className="mb-0">
                                <a
                                  href="/notices"
                                  className="nav-link text-muted"
                                  target="_blank"
                                >
                                  Powered by TransHives © 2022-
                                  {new Date().getFullYear()} | Notices
                                </a>
                              </p>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </LoadingOverlay>
                </div>
              </div>{" "}
              <br />
            </div>
          </div>
        </main>
      ) : (
        <T360Intro
          setQLevel={() => {
            setQLevel(1);
            fetchData(1);
          }}
        />
      )}

      {isShowModal && modalToShow === "confirmSubmit" && (
        <T360SubmitModal
          closeModal={() => setIsShowModal(false)}
          t360Submit={() => t360Submit()}
        />
      )}

      {isShowModal && modalToShow === "showSuccess" && (
        <ShowSuccessModal
          header="Submited"
          text="You successfully submitted your T360 answers. We wish you all the best.
          Please click the below to close and go to your profile."
          type="withRedirect"
          redirectTo="/profile"
        />
      )}

      {isShowAuth && (
        <Auth isFromApply={false} onAuth={() => console.log("")} />
      )}
    </>
  );
}

export default T360;
