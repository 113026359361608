import React from "react";

import { SmartH6, SmartP } from "../../common/text";
import { getDate } from "../../../utils/utils";
import Button from "../../common/button";

function CandidateCard(props) {
  return (
    <div className="col-lg-4 col-md-12" style={{ marginTop: "15px" }}>
      <div className="card h-100">
        <div className="card-header pb-0 p-3">
          <div className="row">
            <div className="col-9">
              <SmartH6
                _id={"jobName-" + props.candidate._id}
                text={displayCandidateName(props.candidate)}
                maxLength={25}
              />
              <SmartP
                _id={"jobName-" + props.candidate._id}
                text={props.candidate.email}
                maxLength={25}
              />
            </div>
            <Button
              type={"aArrow"}
              colSize={3}
              text={"View"}
              onClick={() => props.viewCandidate()}
            />
          </div>
        </div>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <p
                className="text-secondary font-weight-bold text-xs mt-1 mb-0"
                style={{ marginBottom: "15px" }}
              >
                {displayTScore(props.candidate.tScore)}Applied:{" "}
                {getDate(props.candidate.createdOn)}
              </p>
            </div>
            <div className="col-4" style={{ textAlign: "right" }}>
              {displayStatus(props.candidate.stage)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function displayTScore(tScore) {
    const tScoreAsPercentage = tScore * 100;
    if (tScoreAsPercentage < 15.1) return "";
    else return "Score: " + tScoreAsPercentage.toFixed(2) + " | ";
  }

  function displayStatus(status) {
    if (status === "new") {
      return <span className="badge bg-gradient-info ms-auto">{status}</span>;
    } else if (status === "inReview") {
      return (
        <span className="badge bg-gradient-success ms-auto">{status}</span>
      );
    } else if (status === "reserve") {
      return (
        <span className="badge bg-gradient-warning ms-auto">{status}</span>
      );
    } else if (status === "rejected") {
      return <span className="badge bg-gradient-danger ms-auto">{status}</span>;
    }
  }

  function displayCandidateName(candidate) {
    if (candidate.firstName || candidate.lastName)
      return props.candidate.firstName + " " + props.candidate.lastName;
    else return "T360 Pending...";
  }
}

export default CandidateCard;
