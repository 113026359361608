import React from "react";

import { SmartH6, SmartP } from "../../common/text";
import { getDate, isWithinMonth } from "../../../utils/utils";
import Button from "../../common/button";

function JobCard(props) {
  return (
    <div className="col-lg-4 col-md-12" style={{ marginTop: "15px" }}>
      <div className="card h-100">
        <div className="card-header pb-0 p-3">
          <div className="row">
            <div className="col-9">
              <SmartH6
                _id={"jobName-" + props.job._id}
                text={props.job.jobName}
                maxLength={25}
              />
              <SmartP
                _id={"location-" + props.job._id}
                text={props.job.location}
                maxLength={25}
              />
            </div>
            <Button
              type={"aArrow"}
              colSize={3}
              text={"View"}
              onClick={() => props.viewJob()}
            />
          </div>
        </div>

        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <p
                className="text-secondary font-weight-bold text-xs mt-1 mb-0"
                style={{ marginBottom: "15px" }}
              >
                Posted: {getDate(props.job.createdOn)}
              </p>
            </div>
            <div className="col-4" style={{ textAlign: "right" }}>
              {displayStatus(props.job.status)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function displayStatus(status) {
    if (status === "active") {
      return <span className="badge bg-gradient-info ms-auto">{status}</span>;
    } else if (status === "completed") {
      return (
        <span className="badge bg-gradient-secondary ms-auto">{status}</span>
      );
    }
  }
}

export default JobCard;
