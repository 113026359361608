import React from "react";
import Button from "../common/button";

function PageTitle(props) {
  return (
    <>
      <h2 className="font-weight-bolder">{props.title}</h2>
      <p className="mb-1 text-bold">{props.helpText}</p>
      {props.actionButton && (
        <Button
          marginTop={"10px"}
          type={"withBgNoIcon"}
          colSize={2}
          text={props.actionButtonText}
          onClick={() => props.actionButtonClicked()}
        />
      )}
      <hr className="horizontal dark" />
      <br />
    </>
  );
}

export default PageTitle;
